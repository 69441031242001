import axios from 'axios';
import FormData from 'form-data';
import {
  EQUIPMENTS_PATH,
  TREE_NAVIGATION_PATH,
  LOCATIONS_PATH,
  SPARE_PARTS_PATH,
  DOWNLOAD_PATH,
  COMPONENTS_PATH,
  CUSTOMER_REQUESTS_PATH,
  DRAWING_DOCUMENTS_PATH,
  INSTRUCTIONS_PATH,
  NOTES_PATH,
  NPM_CONTROLLER,
  EQUIPMENTS_AUTHORIZATION_PATH,
  DOCUMENT_PATH,
  BUSINESS_CONFIGURATION,
  DASHBOARD_LOCATION_PATH,
  DOCUMENT_CATEGORIES_PATH,
  EQUIPMENTS_OVERVIEW_PATH,
} from '../constants/apiPaths';
import {
  RESET_CACHE_HEADER as cacheHeader,
} from '../constants/requestConstants';
import {
  portalApi,
  portalV2Api,
} from '@/services/AxiosService';

export const EquipmentService = {
  fetchEquipmentTree() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${TREE_NAVIGATION_PATH}/`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => reject(e));
    });
  },

  getEquipments(location = null) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${EQUIPMENTS_PATH}/location/${location}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          console.error('EQUIPMENTS ERROR', e);
          reject(e);
        });
    });
  },
  /**
   * Gets an equipment by id
   * @param {String} id
   * @returns {Promise<Object>}
   */
  getEquipmentById(id, resetCache = false) {
    const headers = resetCache ? cacheHeader : {};
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${EQUIPMENTS_PATH}/${id}`, {
          headers,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => reject(e));
    });
  },
  /**
   * Makes a request to an aws api gateway to get the equipment components
   * @param {String} id id of the equipment for which to get the components
   * @param {Boolean} isPartial specifies if the request should only bring id, name, objectType and leafNode attribute
   * @returns {Promise}
   */
  getEquipmentComponents(id) {
    const EQUIPMENT_COMPONENTS_PATH = `${EQUIPMENTS_PATH}/${id}/${COMPONENTS_PATH}`;
    return new Promise((resolve, reject) => {
      portalApi
        .get(EQUIPMENT_COMPONENTS_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * Adds new note for an equipment
   *@param {String} equipmentId
   * @param {Object} note
   * @returns {Promise<Object>}
   */
  async addNote(equipmentId, note) {
    const EQUIPMENT_NOTE_PATH = `${EQUIPMENTS_PATH}/${equipmentId}/${NOTES_PATH}/`;
    const response = await axios.post(EQUIPMENT_NOTE_PATH, note);
    return response.data.response;
  },
  /**
   * Makes an API request to get the documents of and drawings for an equipment
   * @param {String} id id of the equipment
   * @returns {Promise}
   */
  getEquipmentDocuments(equipmentId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${EQUIPMENTS_PATH}/${equipmentId}/${DOCUMENT_PATH}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getEquipmentDocumentCategories(equipmentId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `${EQUIPMENTS_PATH}/${equipmentId}/${DOCUMENT_PATH}/${DOCUMENT_CATEGORIES_PATH}`,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  createEquipmentDocumentCategory(equipmentId, categoryName) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(
          `${EQUIPMENTS_PATH}/${equipmentId}/${DOCUMENT_PATH}/${DOCUMENT_CATEGORIES_PATH}`,
          {
            categoryName,
          },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Gets the image documents for an component
   * @param {String} equipmentId
   * @param {Number} imageWidth specifies the width for the image
   * @returns {Promise<Array>}
   */
  getEquipmentDrawingDocuments(equipmentId, imageWidth) {
    const EQUIPMENT_DRAWINGS_PATH = `${EQUIPMENTS_PATH}/${equipmentId}/${DRAWING_DOCUMENTS_PATH}`;
    return new Promise((resolve, reject) => {
      portalApi
        .get(EQUIPMENT_DRAWINGS_PATH, {
          params: {
            language: 'en',
            width: imageWidth,
          },
        })
        .then((response) => resolve(response.data.documentDrawings))
        .catch((e) => reject(e));
    });
  },
  /**
   * Makes an API request to get the base64 encoded file of an document
   * @param {String} equipmentId id of the equipment
   * @param {String} documentId id of the document
   * @returns {Promise<Blob>}
   */
  getDocumentFile(equipmentId, documentId) {
    const EQUIPMENT_DOCUMENTS_PATH = `${EQUIPMENTS_PATH}/${equipmentId}/documents/${documentId}/${DOWNLOAD_PATH}`;
    return new Promise((resolve, reject) => {
      portalApi
        .get(EQUIPMENT_DOCUMENTS_PATH, {
          params: {
            language: 'en',
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * Makes a request to an aws api gateway to get the equipment spare parts
   * @param {String} id id of the equipment for which to get the spare parts
   * @returns {Promise}
   */
  getEquipmentSpareParts(id) {
    const EQUIPMENT_SPARE_PARTS_PATH = `${EQUIPMENTS_PATH}/${id}/${SPARE_PARTS_PATH}`;
    return new Promise((resolve, reject) => {
      portalApi
        .get(EQUIPMENT_SPARE_PARTS_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * Makes a request to an aws api gateway to get location structure by location id
   * @param {String} locationId
   * @returns {Promise}
   */
  getLocations(locationId = undefined) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(LOCATIONS_PATH + (locationId ? `/${locationId}` : ''))
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Gets customer request for an equipment
   * @param {String} equipmentId
   * @param {Boolean} resetCache
   * @returns {Promise<Array>}
   */
  getEquipmentCustomerRequests(equipmentId, type) {
    const EQUIPMENT_CUSTOMER_REQUESTS_PATH = `${EQUIPMENTS_PATH}/${CUSTOMER_REQUESTS_PATH}/${equipmentId}?type=${type}`;
    return new Promise((resolve, reject) => {
      portalApi
        .get(EQUIPMENT_CUSTOMER_REQUESTS_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Makes a post request to add a new customer request for an equipment
   * @param {String} equipmentId
   * @param {Object} body contains customer request attributes
   * @returns {Promise<Object>}
   */
  addEquipmentCustomerRequest(body) {
    const EQUIPMENT_CUSTOMER_REQUESTS_PATH = `${EQUIPMENTS_PATH}/${CUSTOMER_REQUESTS_PATH}`;
    const payload = {};
    for (const key of Object.keys(body)) {
      if (body[key] && key !== 'files') {
        payload[key] = body[key];
      }
    }
    return new Promise((resolve, reject) => {
      portalApi
        .post(EQUIPMENT_CUSTOMER_REQUESTS_PATH, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Gets customer request details from hubspot
   * @param {String} hubSpotTicketId
   * @returns {Promise<Array>}
   */
  getCustomerRequestDetails(hubSpotTicketId) {
    const CUSTOMER_REQUEST_PATH = `${EQUIPMENTS_PATH}/${CUSTOMER_REQUESTS_PATH}/details/${hubSpotTicketId}`;
    return new Promise((resolve, reject) => {
      portalApi
        .get(CUSTOMER_REQUEST_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 404 || error.response.status === 400) {
            resolve({
              content: '',
              hs_pipeline_stage: '5',
            });
          } else {
            reject(error);
          }
        });
    });
  },
  sendFileToHubspot(file) {
    const formData = new FormData();
    formData.append('file_content', file, file.name);
    const HUBSPOT_SEND_FILE_PATH = `${EQUIPMENTS_PATH}/${CUSTOMER_REQUESTS_PATH}/hubspot-upload-file/${file.name}`;
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${HUBSPOT_SEND_FILE_PATH}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  attachFilesToHubspotTicket(payload) {
    const CUSTOMER_REQUESTS_ATTACH_FILE_PATH = `${EQUIPMENTS_PATH}/${CUSTOMER_REQUESTS_PATH}/attach-files`;
    return new Promise((resolve, reject) => {
      portalApi
        .post(CUSTOMER_REQUESTS_ATTACH_FILE_PATH, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  uploadDocumentsFiles(file, equipmentId, desc, category) {
    const formData = new FormData();
    formData.append('file_content', file, file.name);
    return new Promise((resolve, reject) => {
      portalApi
        .post(
          `equipments/${equipmentId}/documents/upload/${file.name}?description=${desc}&category=${category}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Gets announcements for an equipment
   * @param {String} equipmentId
   * @param {String} status
   * @returns {Promise<Array>}
   */
  // async getEquipmentAnnouncements(equipmentId, status) {
  async getEquipmentAnnouncements() {
    return [];
    // const EQUIPMENT_ANNOUNCEMENTS_PATH = `${EQUIPMENTS_PATH}/${equipmentId}/${ANNOUNCEMENTS_PATH}`;
    // return new Promise((resolve, reject) => {
    //   axios
    //     .get(EQUIPMENT_ANNOUNCEMENTS_PATH, { params: { status } })
    //     .then(response => {
    //       resolve(response.data);
    //     })
    //     .catch(error => {
    //       reject(error);
    //     });
    // });
  },
  // /**
  //  * Gets instructions for an equipment
  //  * @param {String} equipmentId
  //  * @returns {Promise<Array>}
  //  */
  // getEquipmentInstructions(equipmentId) {
  //   return new Promise((resolve, reject) => {
  //     portalApi
  //       .get(`${EQUIPMENTS_PATH}/${equipmentId}/${DOCUMENT_PATH}`)
  //       .then(response => {
  //         resolve(response.data.instructions);
  //       })
  //       .catch(e => {
  //         reject(e);
  //       });
  //   });
  // },
  getEquipmentAuthorization(equipmentId) {
    const EQUIPMENT_AUTH_PATH = `${EQUIPMENTS_PATH}/${EQUIPMENTS_AUTHORIZATION_PATH}/${equipmentId}`;
    return new Promise((resolve, reject) => {
      portalApi
        .get(EQUIPMENT_AUTH_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Returns instruction details
   * @param {String} equipmentId
   * @param {String} instructionId
   * @param {Number} instructionStatus
   * @returns  {Promise<Object>}
   */
  getEquipmentInstructionDetails(
    equipmentId,
    instructionId,
    instructionStatus,
  ) {
    const EQUIPMENT_INSTRUCTION_PATH = `${EQUIPMENTS_PATH}/${equipmentId}/${INSTRUCTIONS_PATH}/${instructionId}`;
    return new Promise((resolve, reject) => {
      portalApi
        .get(EQUIPMENT_INSTRUCTION_PATH, {
          params: {
            status: instructionStatus,
          },
        })
        .then((response) => resolve(response.data))
        .catch((e) => reject(e));
    });
  },
  // getFilesCounterValue(equipmentId) {
  //   return new Promise((resolve, reject) => {
  //     portalApi
  //       .get(`${EQUIPMENTS_PATH}/${equipmentId}/${DOCUMENT_PATH}`)
  //       .then(response => {
  //         resolve(response.data.documents_count);
  //       })
  //       .catch(e => {
  //         reject(e);
  //       });
  //   });
  // },
  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  },
  deleteDocumentFile(equipmentId, documentId) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(`equipments/${equipmentId}/documents/delete/${documentId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getEquipmentPlannedUsage(equipmentId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${BUSINESS_CONFIGURATION}/${equipmentId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getLocationPlannedUsage(locationId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `${BUSINESS_CONFIGURATION}/${DASHBOARD_LOCATION_PATH}/${locationId}`,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateEquipmentPlannedUsage(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${BUSINESS_CONFIGURATION}/`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateEquipment(equipmentId, payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${EQUIPMENTS_PATH}/${equipmentId}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  addEquipment(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${EQUIPMENTS_PATH}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  addComponent(equipmentId, payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${EQUIPMENTS_PATH}/${equipmentId}/${COMPONENTS_PATH}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getSparePartsList() {
    return new Promise((resolve, reject) => {
      portalApi
        .get(`${SPARE_PARTS_PATH}/`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  addSparePartsToEquipments(equipmentId, sparePartMasterId) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${EQUIPMENTS_PATH}/${equipmentId}/${SPARE_PARTS_PATH}`, {
          sparePartMasterId,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  addNewSpareParts(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(`${EQUIPMENTS_PATH}/${SPARE_PARTS_PATH}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteComponent(equipmentId, componentId) {
    return new Promise((resolve, reject) => {
      portalApi
        .delete(`${EQUIPMENTS_PATH}/${equipmentId}/${COMPONENTS_PATH}`, {
          params: {
            componentId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateComponent(equipmentId, payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${EQUIPMENTS_PATH}/${equipmentId}/${COMPONENTS_PATH}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateSparePart(payload) {
    return new Promise((resolve, reject) => {
      portalApi
        .put(`${EQUIPMENTS_PATH}/${SPARE_PARTS_PATH}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getNpmControllerViewHeader({
    customerCode, location, systemId, subSystemId,
  }) {
    return new Promise((resolve, reject) => {
      portalV2Api
        .get(`${NPM_CONTROLLER}/headers`, {
          params: {
            customerCode,
            location,
            systemId,
            subSystemId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getNpmControllerViewGUI({
    customerCode, location, systemId, subSystemId,
  }) {
    return new Promise((resolve, reject) => {
      portalV2Api
        .get(`${NPM_CONTROLLER}/config`, {
          params: {
            customerCode,
            location,
            systemId,
            subSystemId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getNpmControllerViewAlarms({
    customerCode, location, systemId, subSystemId, jobId,
  }) {
    return new Promise((resolve, reject) => {
      portalV2Api
        .get(`${NPM_CONTROLLER}/alarms`, {
          params: {
            customerCode,
            location,
            systemId,
            subSystemId,
            jobId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getNpmControllerViewIdentifiers({
    customerCode, location, systemId, subSystemId,
  }) {
    return new Promise((resolve, reject) => {
      portalV2Api
        .get(`${NPM_CONTROLLER}/identifiers`, {
          params: {
            customerCode,
            location,
            systemId,
            subSystemId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getNpmControllerViewReadings({
    customerCode,
    location,
    systemId,
    subSystemId,
    idTypes,
  }) {
    return new Promise((resolve, reject) => {
      portalV2Api
        .get(`${NPM_CONTROLLER}/readings`, {
          params: {
            customerCode,
            location,
            systemId,
            subSystemId,
            typeIdList: JSON.stringify(idTypes),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getAssetOverviewCertificates(equipmentId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `${EQUIPMENTS_PATH}/${EQUIPMENTS_OVERVIEW_PATH}/${equipmentId}/certificates`,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getAssetOverviewCapabilites(equipmentId) {
    return new Promise((resolve, reject) => {
      portalApi
        .get(
          `${EQUIPMENTS_PATH}/${EQUIPMENTS_OVERVIEW_PATH}/${equipmentId}/capabilities`,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateAssetOverview({
    payload, equipmentId,
  }) {
    return new Promise((resolve, reject) => {
      portalApi
        .post(
          `${EQUIPMENTS_PATH}/${EQUIPMENTS_OVERVIEW_PATH}/${equipmentId}`,
          payload,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
