import {
  EquipmentService,
} from '@/services/EquipmentService.js';
import EventBus from '@/utils/event-bus';

export default {
  namespaced: true,
  state: {
    header: {},
    headerUpdatedAtTime: null,
    isLoadingHeader: false,
    guiConfig: [],
    isLoadingGuiConfig: false,
    // all following structures contain the data Record<ChamberId, data>
    alarms: {},
    isLoadingAlarms: {},
    readings: {},
    setpoints: {},
    isLoadingReadings: {},
    identifiers: {},
    isLoadingIdentifiers: {},
  },

  mutations: {
    setHeader(state, header) {
      state.header = header;
      state.headerUpdatedAtTime = Date.now();
    },
    setIsLoadingHeader(state, isLoading) {
      state.isLoadingHeader = isLoading;
    },
    setGuiConfig(state, config) {
      state.guiConfig = config;
    },
    setIsLoadingGuiConfig(state, isLoading) {
      state.isLoadingGuiConfig = isLoading;
    },
    setAlarms(state, {
      subSystemId, alarms,
    }) {
      state.alarms[subSystemId] = alarms;
    },
    setIsLoadingAlarms(state, {
      subSystemId, isLoading,
    }) {
      state.isLoadingAlarms[subSystemId] = isLoading;
    },
    setReadings(state, {
      subSystemId, readings,
    }) {
      state.readings[subSystemId] = readings;
    },
    setSetpoints(state, {
      subSystemId, setpoints,
    }) {
      state.setpoints[subSystemId] = setpoints;
    },
    setIsLoadingReadings(state, {
      subSystemId, isLoading,
    }) {
      state.isLoadingReadings[subSystemId] = isLoading;
    },
    setIdentifiers(state, {
      subSystemId, identifiers,
    }) {
      state.identifiers[subSystemId] = identifiers;
    },
    setIsLoadingIdentifiers(state, {
      subSystemId, isLoading,
    }) {
      state.isLoadingIdentifiers[subSystemId] = isLoading;
    },
  },

  actions: {
    RESET({
      commit,
    }) {
      commit('setIdentifiers', {});
      commit('setSetpoints', {});
      commit('setReadings', {});
      commit('setGuiConfig', []);
    },
    async LOAD_HEADER(context, {
      customerCode, location, systemId, subSystemId,
    } = {}) {
      let header = {};
      try {
        context.commit('setIsLoadingHeader', true);
        context.commit('setHeader', header);
        header = await EquipmentService.getNpmControllerViewHeader({
          customerCode,
          location,
          systemId,
          subSystemId,
        });
      } catch (error) {
        EventBus.$emit('api_error', error);
      } finally {
        context.commit('setHeader', header);
        context.commit('setIsLoadingHeader', false);
      }
    },
    async LOAD_GUI_CONFIG(context, {
      customerCode, location, systemId, subSystemId,
    } = {}) {
      try {
        context.commit('setIsLoadingGuiConfig', true);
        const response = await EquipmentService.getNpmControllerViewGUI({
          customerCode,
          location,
          systemId,
          subSystemId,
        });
        context.commit('setGuiConfig', response);
      } catch (error) {
        EventBus.$emit('api_error', error);
        context.commit('setGuiConfig', null);
      } finally {
        context.commit('setIsLoadingGuiConfig', false);
      }
    },
    async LOAD_ALARMS(
      context,
      {
        customerCode, location, systemId, subSystemId, jobId,
      } = {},
    ) {
      try {
        context.commit('setIsLoadingAlarms', {
          subSystemId,
          isLoading: true,
        });
        const response = await EquipmentService.getNpmControllerViewAlarms({
          customerCode,
          location,
          systemId,
          subSystemId,
          jobId,
        });

        const sensorTypeMapping = {
          AI: 0,
          AO: 1,
          DI: 2,
          DO: 3,
        };
        const groupByIdType = (items) => items.reduce((acc, item) => {
          const idType = `${item.compId}_${
            sensorTypeMapping[item.sensorType]
          }`;
          acc[idType] = item;
          return acc;
        }, {});
        const alarms = groupByIdType(response);

        context.commit('setAlarms', {
          subSystemId,
          alarms,
        });
      } catch (error) {
        EventBus.$emit('api_error', error);
        context.commit('setAlarms', {
          subSystemId,
          alarms: {},
        });
      } finally {
        context.commit('setIsLoadingAlarms', {
          subSystemId,
          isLoading: false,
        });
      }
    },
    async LOAD_IDENTIFIERS(context, {
      customerCode, location, systemId, subSystemId,
    } = {}) {
      try {
        context.commit('setIsLoadingIdentifiers', {
          subSystemId,
          isLoading: true,
        });
        const response = await EquipmentService.getNpmControllerViewIdentifiers(
          {
            customerCode,
            location,
            systemId,
            subSystemId,
          },
        );

        const sensorTypeMapping = {
          A: 0,
          a: 1,
          D: 2,
          d: 3,
        };
        const objectsByIdType = (items) => items.reduce((acc, item) => {
          const idType = `${item.compId}_${sensorTypeMapping[item.type]}`;
          acc[idType] = item;
          return acc;
        }, {});
        const identifiers = objectsByIdType(
          response.filter((item) => [
            'A',
            'a',
            'D',
            'd',
          ].includes(item.type)),
        );
        context.commit('setIdentifiers', {
          subSystemId,
          identifiers,
        });
      } catch (error) {
        EventBus.$emit('api_error', error);
        context.commit('setIdentifiers', {});
      } finally {
        context.commit('setIsLoadingIdentifiers', {
          subSystemId,
          isLoading: false,
        });
      }
    },
    async LOAD_READINGS(
      context,
      {
        customerCode, location, systemId, subSystemId, idTypes,
      } = {},
    ) {
      if (context.state.isLoadingReadings[subSystemId]) {
        EventBus.$emit(
          'api_error',
          new Error(
            'Cannot start LOAD_READINGS before previous LOAD_READINGS is finished',
          ),
        );
        return;
      }

      let readings = {};
      let setpoints = {};
      try {
        context.commit('setIsLoadingReadings', {
          subSystemId,
          isLoading: true,
        });
        const response = await EquipmentService.getNpmControllerViewReadings({
          customerCode,
          location,
          systemId,
          subSystemId,
          idTypes,
        });

        const sensorTypeMapping = {
          AI: 0,
          AO: 1,
          DI: 2,
          DO: 3,
        };
        const groupByIdType = (readings_) => readings_.reduce((acc, reading) => {
          const idType = `${reading.compId}_${
            sensorTypeMapping[reading.sensorType]
          }`;
          acc[idType] = reading;
          return acc;
        }, {});
        readings = groupByIdType(response.filter((reading) => !reading.isSet));
        setpoints = groupByIdType(response.filter((reading) => reading.isSet));
      } catch (error) {
        EventBus.$emit('api_error', error);
      } finally {
        context.commit('setReadings', {
          subSystemId,
          readings,
        });
        context.commit('setSetpoints', {
          subSystemId,
          setpoints,
        });
        context.commit('setIsLoadingReadings', {
          subSystemId,
          isLoading: false,
        });
      }
    },
  },
};
