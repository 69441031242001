export default {
  notAvailable: '-',
  signal: 'Signal',
  unit: 'Einheit',
  xCursor: 'x Cursor',
  x1: 'x1',
  x2: 'x2',
  x2_x1: 'x2-x1',
  yCursor: 'y Cursor',
  y1: 'y1',
  y2: 'y2',
  y2_y1: 'y2-y1',
  min: 'Min',
  max: 'Max',
  average: 'ȳ',
  stdandardDeviation: 'σ',
};
