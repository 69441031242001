import {
  ERouteName,
} from '@/router/route-name.ts';

export default {
  TabMenuLabel: {
    [ERouteName.ASSET_MANAGEMENT_DOCUMENT]: 'Dokumente',
    [ERouteName.ASSET_MANAGEMENT_ASSET]: 'Anlage',
    [ERouteName.ASSET_MANAGEMENT_MAIN]: 'Übersicht',
    [ERouteName.ASSET_MANAGEMENT_MAIN_EQUIPMENT]: 'Übersicht',
    [ERouteName.ASSET_MANAGEMENT_SENSOR_MAPPING]: 'Sensorzuordnung',
    [ERouteName.ASSET_MANAGEMENT_SENSOR_SETTINGS]: 'Sensor-Einstellungen',
    [ERouteName.ASSET_MANAGEMENT_DATA_SOURCES]: 'Datenquellen',
  },
};
