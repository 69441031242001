import {
  EChartTimeFormat,
  TChartTimeFormatTranslation,
} from './select-chart-time-format.ts';

const chartTimeFormats: TChartTimeFormatTranslation = {
  [EChartTimeFormat.COMPACT]: {
    displayName: 'Compact',
    details: 'Displays only time for timeframes shorter than 24 hours',
  },
  [EChartTimeFormat.DETAILED]: {
    displayName: 'Detailed',
    details: 'Always displays the full date',
  },
};

export default {
  placeholder: 'Select chart time format',
  chartTimeFormats,
};
