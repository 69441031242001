export default {
  setpoints: {
    header: 'Sollwerte',
    surfaceHardnessLabel: 'Min. Oberflächenhärte',
    effectiveCaseDepthLabel: 'Aufkohlungstiefe',
    effectiveCasehardnessLabel: 'Grenzhärte',
  },
  properties: {
    header: 'Eigenschaften',
    generalShapeOfPartLabel: 'Teilegeometrie (DIN 17211)',
    grainSizeLabel: 'Korngröße',
    diameterLabel: 'Durchmesser',
    quenchingMediaLabel: 'Abschreckmedium',
    representativeCouponDiameterLabel: 'Maßgeblicher Wärmebehandlungsdurchmesser (DIN 17211)',
    quenchingIntensityLabel: 'Abschreckintensität',
    shapeSimulationTypeLabel: 'Formsimulationstyp',
    halfInfiniteRadio: 'Einseitig (Halbunendlich)',
    shapeOrientedRadio: 'Beidseitig',
    endTemperatureLabel: 'Endtemperatur',
  },
  materialSimulationData: {
    header: 'Daten zur Materialsimulation',
  },
  savePartAs: {
    header: 'Part speichern unter',
  },
};
