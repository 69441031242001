<template>
  <header
    ref="header"
    class="navbar-header"
    style="background-color: var(--color-100);"
  >
    <div class="grouped-item-list grouped-item-list_spaced w-full">
      <div class="grouped-item-list">
        <prm-button
          size="small"
          text
          severity="secondary"
          type="button"
          icon="pi pi-bars"
          style="color: var(--color-224);"
          @click="showSidebar = true"
        />
        <router-link
          v-slot="{ href, navigate }"
          :to="{
            name: ERouteName.HOME
          }"
          custom
        >
          <a
            :href="href"
            :title="i18n.global.t('page.home.title')"
            @click="navigate"
          >
            <qmulus-logo style="width: 177px; height: 40px;" />
          </a>
        </router-link>
      </div>
      <div>
        <prm-skeleton
          v-if="appStore.isNavigating"
          width="40px"
        />
        <template v-else>
          <div class="typo-h3">
            {{ route.meta.titleI18nKey
              ? i18n.global.t(route.meta.titleI18nKey)
              : getAppTitle() }}
          </div>
        </template>
      </div>
      <div
        style="
          display: flex;
          gap: var(--gap-union);
        "
      >
        <overlay-panel-notification />

        <button
          type="button"
          size="large"
          class="grouped-item-list"
          @click="userMenu!.toggle"
        >
          <prm-avatar :label="initials" />
        </button>
        <prm-menu
          ref="userMenu"
          popup
          :model="items"
          style="width: 18rem;"
        >
          <template #start>
            <div class="p-4 font-weight-bold">
              <prm-avatar :label="initials" />
              <span class="pl-2">{{ appStore.user?.displayName || '' }}</span>
            </div>
          </template>
          <template #item="{ item, props }">
            <router-link
              v-if="item.routeName"
              v-slot="{ href, navigate }"
              :to="item.routeName"
              custom
            >
              <a
                v-ripple
                :href="href"
                v-bind="props.action"
                class="navbar-header__menu-item"
                :style="item.style"
                @click="navigate"
              >
                <span :class="item.icon" />
                <span class="ml-2">{{ item.label }}</span>
              </a>
            </router-link>
            <button
              v-else-if="item.command"
              v-ripple
              v-bind="props.action"
              :style="item.style"
              :disabled="item.disabled as (boolean | undefined)"
              type="button"
              class="w-full navbar-header__menu-item"
            >
              <span
                v-if="item.icon"
                :class="item.icon"
              />
              <component
                :is="item.iconComponent"
                v-if="item.iconComponent"
              />
              <span class="ml-2">{{ item.label }}</span>
            </button>
            <div
              v-else
              class="navbar-header__menu-item"
              :style="item.style"
              @hover.capture.stop
              @click.capture.stop
            >
              <span class="mx-4">{{ item.label }}</span>
            </div>
          </template>
          <template
            #end
          >
            <div
              class="p-4"
              style="display: flex; justify-content: space-between;"
            >
              <router-link
                v-slot="{ href, navigate }"
                :to="{
                  name: ERouteName.PRIVACY_POLICY
                }"
                custom
              >
                <a
                  v-ripple
                  :href="href"
                  class="navbar-header__menu-item"
                  @click="navigate"
                >
                  {{ i18n.global.t('page.privacyPolicy.title') }}
                </a>
              </router-link>
              <router-link
                v-slot="{ href, navigate }"
                :to="{
                  name: ERouteName.RELEASE_NOTES
                }"
                custom
              >
                <a
                  v-ripple
                  :href="href"
                  class="navbar-header__menu-item"
                  @click="navigate"
                >
                  <span class="font-bold">{{ packageInfo.version }}</span>
                </a>
              </router-link>
            </div>
          </template>
        </prm-menu>
      </div>
    </div>
    <sidebar
      v-model:visible="showSidebar"
      @close="showSidebar = false"
    />
    <modal-bug-report
      v-if="showModalBugReport"
      @close="showModalBugReport = false"
    />
  </header>
</template>

<script lang="ts" setup>
import {
  i18n,
} from '@i18n';
import {
  shallowRef,
  ref,
  onMounted,
  onBeforeUnmount,
  computed,
} from 'vue';
import PrmMenu from 'primevue/menu';
import PrmAvatar from 'primevue/avatar';
import PrmButton from 'primevue/button';
import PrmSkeleton from 'primevue/skeleton';
import {
  useRoute,
  useRouter,
} from 'vue-router';
import {
  STORAGE_KEY,
} from 'axios-jwt/dist/src/StorageKey';
import Sidebar from '@/components/navbar/sidebar.vue';
import {
  ERouteName,
} from '@/router/route-name.ts';
import QmulusLogo from '@/assets/qmulus-logo.svg?component';
import {
  useAppStore,
} from '@/store/pinia/app.ts';
import {
  initOptions,
} from '@/constants/ssoOptions';
import packageInfo from '@/../package.json';
import {
  AxiosService,
} from '@/services/AxiosService.ts';
import {
  getAppTitle,
} from '@/utils/string-utils.ts';
import {
  rafCallback,
} from '@/utils/raf';
import {
  useCustomUserStorageStore,
} from '@/store/pinia/custom-user-storage';
import HomeIcon from '@/assets/icon/home.svg?component';
import {
  getUserInitials,
} from '@/utils/user-name.ts';
import OverlayPanelNotification from '@/components/overlay-panel-notification/overlay-panel-notification.vue';
import ModalBugReport from '@/components/modal-bug-report/modal-bug-report.vue';

const I18N_PREFIX = 'components/navbar/header';

const appStore = useAppStore();
const route = useRoute();
const router = useRouter();
const customUserStorageStore = useCustomUserStorageStore();

const showSidebar = shallowRef(false);

const showModalBugReport = shallowRef(false);

const userMenu = shallowRef<InstanceType<typeof PrmMenu>>();
const header = ref<HTMLElement | null>(null);

const initials = computed<string>(() => {
  if (appStore.user === undefined) {
    return '';
  }
  // belows syntax is able to prevent lone surrogates on unicode characters, such as
  // chinese characters, from being split into two characters
  return getUserInitials(appStore.user!.firstName, appStore.user!.lastName);
});

function logout() {
  const tokens = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');
  AxiosService.logout();
  let logoutUrl = initOptions.logoutUrl;
  if (tokens.idToken) {
    const {
      href: loginUrl,
    } = router.resolve({
      name: ERouteName.LOGIN,
    });
    const absoluteLoginURL = new URL(loginUrl, window.location.origin).href;
    logoutUrl += `?post_logout_redirect_uri=${encodeURIComponent(absoluteLoginURL)}&id_token_hint=${tokens.idToken}`;
  }
  window.location.replace(logoutUrl);
}

const items = computed(() => ([
  {
    separator: true,
  },
  {
    label: i18n.global.t(`${I18N_PREFIX}.savePageAsDefault`),
    iconComponent: HomeIcon,
    disabled: route.name === ERouteName.HOME,
    command: () => {
      customUserStorageStore.createOrUpdateCustomUserSettings({
        ...customUserStorageStore.customUserSettings!,
        homePageRoute: {
          name: router.currentRoute.value.name!,
          params: router.currentRoute.value.params || undefined,
          query: router.currentRoute.value.query || undefined,
        },
      });
    },
  },
  {
    label: i18n.global.t(`${I18N_PREFIX}.resetDefaultPage`),
    icon: 'pi pi-undo',
    disabled: !customUserStorageStore.customUserSettings!.homePageRoute,
    command: () => {
      customUserStorageStore.createOrUpdateCustomUserSettings({
        ...customUserStorageStore.customUserSettings!,
        homePageRoute: undefined,
      });
    },
  },
  {
    separator: true,
  },
  {
    label: i18n.global.t('common.logout'),
    icon: 'pi pi-sign-out',
    style: {
      color: 'var(--error-color)',
    },
    command: logout,
  },
  {
    separator: true,
  },
  {
    label: i18n.global.t(`${I18N_PREFIX}.bugReport`),
    icon: 'pi pi-megaphone',
    command: () => {
      showModalBugReport.value = true;
    },
  },
  {
    separator: true,
  },
]));

const onResize = rafCallback(() => {
  if (!header.value) {
    return;
  }
  document.documentElement.style.setProperty('--navbar-header-height', `${header.value!.offsetHeight}px`);
});
onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
  header.value!.addEventListener('resize', onResize);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
  header.value!.removeEventListener('resize', onResize);
});
</script>

<style lang="scss" scoped>
@use '@/styles/util/z-index.scss';

.navbar-header {
  z-index: z-index.$middle;
  @media print {
    display: none;
  }
}
.navbar-header__menu-item {
  text-align: start;
  word-break: break-word;
  svg {
    width: 16px;
    height: 16px;
  }
}
</style>
