<template>
  <qmulus-button
    v-bind="{
      ...props,
      ...variantMap[variant],
    }"
    :label="label || variantMap[variant].label"
  />
</template>

<script setup lang="ts">
import {
  i18n,
} from '@i18n';
import {
  computed,
} from 'vue';
import {
  IQmulusButtonProps,
} from './qmulus-button.ts';
import QmulusButton from './qmulus-button.vue';
import {
  TSemanticButtonVariant,
} from './semantic-button.ts';

export interface ISemanticButtonProps extends Omit<IQmulusButtonProps, 'icon' | 'label'> {
  variant: TSemanticButtonVariant,
  label?: string,
}

const props = defineProps<ISemanticButtonProps>();

const variantMap = computed<Record<TSemanticButtonVariant, IQmulusButtonProps>>(() => ({
  cancel: {
    label: i18n.global.t('common.cancel'),
    severity: props.isInlineAction
      ? 'tertiary'
      : 'secondary',
    icon: (props.iconOnly || props.isInlineAction) ? 'pi pi-times' : undefined,
  },
  back: {
    label: i18n.global.t('common.back'),
    severity: 'tertiary',
    icon: (props.iconOnly || props.isInlineAction) ? 'pi pi-arrow-left' : undefined,
  },
  forward: {
    label: i18n.global.t('common.forward'),
    severity: 'tertiary',
    icon: (props.iconOnly || props.isInlineAction) ? 'pi pi-arrow-right' : undefined,
  },
  edit: {
    label: i18n.global.t('common.edit'),
    severity: props.isInlineAction
      ? 'tertiary'
      : 'primary',
    icon: 'pi pi-pencil',
  },
  save: {
    label: i18n.global.t('common.save'),
    severity: props.isInlineAction
      ? 'tertiary'
      : 'primary',
    icon: 'pi pi-save',
  },
  accept: {
    label: i18n.global.t('common.accept'),
    severity: props.isInlineAction
      ? 'tertiary'
      : 'primary',
    icon: 'pi pi-check',
  },
  saveAsNew: {
    label: i18n.global.t('common.saveAsNew'),
    severity: 'tertiary',
    icon: 'pi pi-copy',
  },
  delete: {
    label: i18n.global.t('common.delete'),
    severity: 'danger',
    icon: 'pi pi-trash',
  },
  reset: {
    label: i18n.global.t('common.reset'),
    severity: 'tertiary',
    icon: 'pi pi-undo',
    isInlineAction: true,
  },
  add: {
    label: i18n.global.t('common.add'),
    severity: 'tertiary',
    icon: 'pi pi-plus',
  },
  create: {
    label: i18n.global.t('common.create'),
    severity: props.isInlineAction
      ? 'tertiary'
      : 'primary',
    icon: 'pi pi-plus',
  },
  settings: {
    label: i18n.global.t('common.settings'),
    severity: 'tertiary',
    icon: 'pi pi-cog',
    iconOnly: true,
  },
  tableNavigation: {
    label: '',
    severity: 'primary',
    icon: 'pi pi-arrow-right',
    iconOnly: true,
    isInlineAction: true,
  },
}));

</script>
