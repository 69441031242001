import AccountIcon from '@/assets/icon/account.svg?component';
import AddIcon from '@/assets/icon/add.svg?component';
import AdminIcon from '@/assets/icon/admin.svg?component';
import AinIcon from '@/assets/icon/ain.svg?component';
import AlarmStatIcon from '@/assets/icon/alarm-stat.svg?component';
import AnomalyDetectionIcon from '@/assets/icon/anomaly-detection.svg?component';
import AppsIcon from '@/assets/icon/apps.svg?component';
import ArrowBackIcon from '@/assets/icon/arrow-back.svg?component';
import ArrowDownIcon from '@/assets/icon/arrow-down.svg?component';
import ArrowLeftIcon from '@/assets/icon/arrow-left.svg?component';
import ArrowRightIcon from '@/assets/icon/arrow-right.svg?component';
import ArrowUpIcon from '@/assets/icon/arrow-up.svg?component';
import AssetManagementIcon from '@/assets/icon/asset-management.svg?component';
import CancelIcon from '@/assets/icon/cancel.svg?component';
import CertificationIcon from '@/assets/icon/certification.svg?component';
import DatabaseIcon from '@/assets/icon/database.svg?component';
import DeleteAlertIcon from '@/assets/icon/delete-alert.svg?component';
import DigitalSupportIcon from '@/assets/icon/digital-support.svg?component';
import DoubleArrowDownIcon from '@/assets/icon/double-arrow-down.svg?component';
import DoubleArrowLeftIcon from '@/assets/icon/double-arrow-left.svg?component';
import DoubleArrowRightIcon from '@/assets/icon/double-arrow-right.svg?component';
import DoubleArrowUpIcon from '@/assets/icon/double-arrow-up.svg?component';
import DownArrowIcon from '@/assets/icon/down-arrow.svg?component';
import EditAlertIcon from '@/assets/icon/edit-alert.svg?component';
import FilterIcon from '@/assets/icon/filter.svg?component';
import FilterOnIcon from '@/assets/icon/filter-on.svg?component';
import GrafanaIcon from '@/assets/icon/grafana.svg?component';
import HelpIcon from '@/assets/icon/help.svg?component';
import HistoryAndTrendsIcon from '@/assets/icon/history-and-trends.svg?component';
import HomeIcon from '@/assets/icon/home.svg?component';
import LeftArrowIcon from '@/assets/icon/left-arrow.svg?component';
import MaintenanceCalendarIcon from '@/assets/icon/maintenance-calendar.svg?component';
import MaterialIcon from '@/assets/icon/material.svg?component';
import MediaEnhancedDocumentationIcon from '@/assets/icon/media-enhanced-documentation.svg?component';
import MonitoringDashboardIcon from '@/assets/icon/monitoring-dashboard.svg?component';
import MonitoringToolingFixturesIcon from '@/assets/icon/monitoring-tooling-fixtures.svg?component';
import MoreIcon from '@/assets/icon/more.svg?component';
import NpmRecipeOnDemandIcon from '@/assets/icon/npm-recipe-on-demand.svg?component';
import OperationDashboardIcon from '@/assets/icon/operation-dashboard.svg?component';
import PerformanceDashboardIcon from '@/assets/icon/performance-dashboard.svg?component';
import PlusIcon from '@/assets/icon/plus.svg?component';
import PredictiveMaintenanceIcon from '@/assets/icon/predictive-maintenance.svg?component';
import ProcessQualityPredictionIcon from '@/assets/icon/process-quality-prediction.svg?component';
import ProductionLogsIcon from '@/assets/icon/production-logs.svg?component';
import ProductionPlanningIcon from '@/assets/icon/production-planning.svg?component';
import ProductionStatisticsIcon from '@/assets/icon/production-statistics.svg?component';
import Pt9800Icon from '@/assets/icon/pt9800.svg?component';
import QmulusIcon from '@/assets/qmulus-logo.svg?component';
import RecipeOnDemandIcon from '@/assets/icon/recipe-on-demand.svg?component';
import RecipesPtIcon from '@/assets/icon/recipes-pt.svg?component';
import RecipesNitrideIcon from '@/assets/icon/recipes-nitride.svg?component';
import RemoveFilterIcon from '@/assets/icon/remove-filter.svg?component';
import RightArrowIcon from '@/assets/icon/right-arrow.svg?component';
import ScadaRemoteControlIcon from '@/assets/icon/scada-remote-control.svg?component';
import SerialNumberTrackingIcon from '@/assets/icon/serial-number-tracking.svg?component';
import SettingsIcon from '@/assets/icon/settings.svg?component';
import ShopIcon from '@/assets/icon/shop.svg?component';
import SimulatorIcon from '@/assets/icon/simulator.svg?component';
import SimulatorPackageIcon from '@/assets/icon/simulator-package.svg?component';
import StateAcceptedIcon from '@/assets/icon/state-accepted.svg?component';
import StateCompletedIcon from '@/assets/icon/state-completed.svg?component';
import StateDoneIcon from '@/assets/icon/state-done.svg?component';
import StateInProgressIcon from '@/assets/icon/state-in-progress.svg?component';
import StatePendingIcon from '@/assets/icon/state-pending.svg?component';
import SuccessAlertIcon from '@/assets/icon/success-alert.svg?component';
import TrackingMeasurementEquipmentIcon from '@/assets/icon/tracking-measurement-equipment.svg?component';
import UniversalChartIcon from '@/assets/icon/universal-chart.svg?component';
import UpArrowIcon from '@/assets/icon/up-arrow.svg?component';
import UpcProIcon from '@/assets/icon/upc-pro.svg?component';
import UpgradeIcon from '@/assets/icon/upgrade.svg?component';
import UserIcon from '@/assets/icon/user.svg?component';
import UtilitiesIcon from '@/assets/icon/utilities.svg?component';
import PartIcon from '@/assets/icon/part.svg?component';
import LiveAppIcon from '@/assets/icon/live-app.svg?component';

const customAliases = {
  account: AccountIcon,
  add: AddIcon,
  admin: AdminIcon,
  ain: AinIcon,
  alarmStatistics: AlarmStatIcon,
  anomalyDetection: AnomalyDetectionIcon,
  apps: AppsIcon,
  arrowBack: ArrowBackIcon,
  arrowDown: ArrowDownIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowUp: ArrowUpIcon,
  assetManagement: AssetManagementIcon,
  cancel: CancelIcon,
  certification: CertificationIcon,
  grafana: GrafanaIcon,
  database: DatabaseIcon,
  deleteAlert: DeleteAlertIcon,
  digitalSupport: DigitalSupportIcon,
  doubleArrowDown: DoubleArrowDownIcon,
  doubleArrowRight: DoubleArrowRightIcon,
  doubleArrowUp: DoubleArrowUpIcon,
  doubleArrowLeft: DoubleArrowLeftIcon,
  downArrow: DownArrowIcon,
  editAlert: EditAlertIcon,
  filter: FilterIcon,
  filterOn: FilterOnIcon,
  help: HelpIcon,
  historyAndTrends: HistoryAndTrendsIcon,
  home: HomeIcon,
  liveApp: LiveAppIcon,
  leftArrow: LeftArrowIcon,
  maintenanceCalendar: MaintenanceCalendarIcon,
  material: MaterialIcon,
  mediaEnhancedDocumentation: MediaEnhancedDocumentationIcon,
  monitoringDashboard: MonitoringDashboardIcon,
  monitoringToolingFixtures: MonitoringToolingFixturesIcon,
  more: MoreIcon,
  operationDashboard: OperationDashboardIcon,
  part: PartIcon,
  performanceDashboard: PerformanceDashboardIcon,
  plus: PlusIcon,
  predictiveMaintenance: PredictiveMaintenanceIcon,
  processQualityPrediction: ProcessQualityPredictionIcon,
  productionLogs: ProductionLogsIcon,
  productionPlanning: ProductionPlanningIcon,
  productionStatistics: ProductionStatisticsIcon,
  pt9800: Pt9800Icon,
  qmulus: QmulusIcon,
  recipeBuilder: RecipesNitrideIcon,
  recipePt9800: RecipesPtIcon,
  recipeOnDemand: RecipeOnDemandIcon,
  removeFilter: RemoveFilterIcon,
  rightArrow: RightArrowIcon,
  scadaRemoteControl: ScadaRemoteControlIcon,
  serialNumberTracking: SerialNumberTrackingIcon,
  settings: SettingsIcon,
  shop: ShopIcon,
  simulator: SimulatorIcon,
  simulatorPackage: SimulatorPackageIcon,
  stateAccepted: StateAcceptedIcon,
  stateCompleted: StateCompletedIcon,
  stateDone: StateDoneIcon,
  stateInProgress: StateInProgressIcon,
  statePending: StatePendingIcon,
  successAlert: SuccessAlertIcon,
  trackingMeasurementEquipment: TrackingMeasurementEquipmentIcon,
  universalChart: UniversalChartIcon,
  upArrow: UpArrowIcon,
  upcPro: UpcProIcon,
  upgrade: UpgradeIcon,
  user: UserIcon,
  utilities: UtilitiesIcon,
  npmRecipeOnDemand: NpmRecipeOnDemandIcon,
};

export {
  customAliases,
};
