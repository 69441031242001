import '@/utils/init-dayjs';
import '@/styles/utility.scss';
import './pt9800/css/app.css';
import './styles/core-styles.css';
import './styles/fonts.css';
import './styles/nitrex-styles.css';
import './styles/snackbar-styles.css';
import './styles/variables.scss';
import './styles/vue-cal-styles.css';
import './styles/vue-simple-calendar-styles.scss';
import './styles/vue-simple-calendar-default-styles.css';
import './styles/vue-simple-calendar-dist-styles.css';
import '@vueform/slider/themes/default.css';
import 'element-plus/dist/index.css';
import 'vue-cal/dist/vuecal.css';
import 'vue3-virtual-scroller/dist/vue3-virtual-scroller.css';
import * as mdijs from '@mdi/js';
import ElementPlus from 'element-plus';
import VueCookies from 'vue3-cookies';
// @ts-ignore
import VueVirtualScroller from 'vue3-virtual-scroller';
import annotations from 'chartjs-plugin-annotation';
import mdiVue from 'mdi-vue/v3';
import zoom from 'chartjs-plugin-zoom';
import {
  Chart, registerables,
} from 'chart.js';
import {
  SnackbarService,
  // @ts-ignore
} from 'vue3-snackbar';
import {
  createApp,
} from 'vue';
import UpcWebplatformDesignSystem from 'upc-webplatform-design-system/plugin/plugin.ts';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import ConfirmationService from 'primevue/confirmationservice';
import {
  VueQueryPlugin,
} from '@tanstack/vue-query';
import * as Sentry from '@sentry/vue';
import router from './router';
import store from '@/store';
import vuetify from './plugins/vuetify';
// musst be after vuetify
import '@/styles/main.scss';
import {
  pt9800APIClient,
} from './services/AxiosService';
import App from './app.vue';
import {
  createPT9800,
} from './plugins/pt9800.js';
import {
  i18n,
} from '@/plugins/i18n';
import GlobalVueUtils from './utils/GlobalVueUtils';
import 'upc-webplatform-design-system/style.css';
import pinia from './store/pinia/export.js';
import config from '@/config/config';
import '@/primevue/filter-service.ts';
import packageInfo from '@/../package.json';

Chart.defaults.borderColor = 'rgba(0, 0, 0, 0.2)';
Chart.defaults.color = '#00000';
Chart.defaults.font.size = 14;
Chart.register(
  ...registerables,
  zoom,
  annotations,
);

const app = createApp(App);

if (config.VITE_SENTRY_DSN && import.meta.env.MODE !== 'development') {
// const debug = import.meta.env.MODE === 'development';
  Sentry.init({
    app,
    dsn: config.VITE_SENTRY_DSN,
    environment: config.VITE_SENTRY_ENVIRONMENT,
    release: `Qmulus@${packageInfo.version}`,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration(),
    ],
    // will spam A LOT in console
    // debug,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // enable if required
    // tracePropagationTargets: [
    //   'local.qmulus.ai',
    //   /^https:\/\/yourserver\.io\/api/,
    // ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(VueQueryPlugin);
app.use(router);
app.use(store);
app.use(pinia);
app.use(mdiVue, {
  icons: mdijs,
});
app.use(
  createPT9800({
    apiClient: pt9800APIClient,
    store,
    storeKey: 'pt9800',
  }),
);
app.use(ElementPlus);
app.use(vuetify);
app.use(i18n);
app.use(SnackbarService);
app.use(VueVirtualScroller);
// @ts-ignore
app.use(VueCookies);
app.use(UpcWebplatformDesignSystem);

// primevue
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);

app.directive('tooltip', Tooltip);

app.mount('#app');

// Todo: replace with reccomended way:
//  https://vuejs.org/api/options-composition.html#mixins
app.mixin({
  ...GlobalVueUtils,
});
