<template>
  <prm-dialog
    v-model:visible="visible"
    :draggable="false"
    modal
    :header="i18n.global.t(`${I18N_PREFIX}.bugReport`)"
    style="
      width: 399px;
      max-width: 100%;
    "
    @hide="emit('close')"
  >
    <form
      :id="FORM_ID"
      @submit.prevent="submit"
    >
      <div class="input-group">
        {{ i18n.global.t('generalTerm.fullName') }}
        <input-text
          v-model="form.displayName"
          :disabled="isSubmitting"
          :invalid="!!formattedErrorMap.displayName"
          :error-message="formattedErrorMap.displayName?._errors[0]"
        />
      </div>
      <div class="input-group">
        {{ i18n.global.t('generalTerm.email') }}
        <input-text
          v-model="form.email"
          :disabled="isSubmitting"
          :invalid="!!formattedErrorMap.email"
          :error-message="formattedErrorMap.email?._errors[0]"
        />
      </div>
      <div class="input-group">
        {{ i18n.global.t('common.description') }}
        <input-textarea
          v-model="form.description"
          :disabled="isSubmitting"
          :placeholder="i18n.global.t(`${I18N_PREFIX}.descriptionPlaceholder`)"
          :invalid="!!formattedErrorMap.description"
          :error-message="formattedErrorMap.description?._errors[0]"
          :input-props="{
            autoResize: true,
            rows: 7,
          }"
        />
      </div>
      <div class="input-group">
        <upload-picture @submit="form.pictureData = $event" />
      </div>
    </form>
    <template #footer>
      <div
        style="
          display: flex;
          flex-direction: row;
          gap: var(--gap-union);
        "
      >
        <semantic-button
          variant="cancel"
          :disabled="isSubmitting"
          @click="emit('close')"
        />
        <semantic-button
          type="submit"
          variant="save"
          :form="FORM_ID"
          :label="i18n.global.t('generalTerm.send')"
          :disabled="isSubmitting"
        />
      </div>
    </template>
  </prm-dialog>
</template>

<script setup lang="ts">
import {
  shallowRef,
  ref,
} from 'vue';
import {
  i18n,
} from '@i18n';
import * as Sentry from '@sentry/vue';
import {
  ZodFormattedError,
  z,
} from 'zod';
import PrmDialog from 'primevue/dialog';
import {
  useToast,
} from 'primevue/usetoast';
import InputText from '@/components/input-text/input-text.vue';
import SemanticButton from '@/components/button/semantic-button.vue';
import {
  useAppStore,
} from '@/store/pinia/app.ts';
import InputTextarea from '@/components/input-textarea/input-textarea.vue';
import UploadPicture from '@/components/upload-picture/upload-picture.vue';
import {
  TPictureData,
} from '@/components/upload-picture/modal-upload.ts';
import {
  imageFileToUint8Array,
} from '@/utils/uint8-array.ts';

const toast = useToast();
const appStore = useAppStore();

const I18N_PREFIX = 'components/modal-bug-report/modal-bug-report';

// eslint-disable-next-line func-call-spacing, no-spaced-func
const emit = defineEmits<{
  (e: 'close'): void,
}>();

const visible = shallowRef(true);

const FORM_ID = Math.random().toString();

type TForm = {
  displayName: string,
  email: string,
  description: string,
  pictureData?: TPictureData,
}
const form = ref<TForm>({
  displayName: appStore.user?.displayName || '',
  email: appStore.user?.email || '',
  description: '',
  pictureData: undefined,
});

const validationSchema = z.object({
  displayName: z.string().min(1),
  email: z.string().email().nullish(),
  description: z.string().min(1),
});

type TFormattedError = ZodFormattedError<z.infer<typeof validationSchema>>;

const formattedErrorMap = shallowRef<TFormattedError>({} as TFormattedError);

async function captureFeedback() {
  if (!form.value.pictureData?.file) {
    Sentry.captureFeedback({
      name: form.value.displayName,
      email: form.value.email,
      message: form.value.description,
    });
    return;
  }

  imageFileToUint8Array(form.value.pictureData.file as File)
    .then((uint8ArrayFile) => {
      Sentry.captureFeedback({
        name: form.value.displayName,
        email: form.value.email,
        message: form.value.description,
      }, {
        attachments: [
          {
            filename: form.value.pictureData!.displayName,
            data: uint8ArrayFile,
          },
        ],
      });
    });
}

const isSubmitting = shallowRef(false);
async function submit() {
  isSubmitting.value = true;
  formattedErrorMap.value = {} as TFormattedError;
  const result = validationSchema.safeParse(form.value);
  if (!result.success) {
    formattedErrorMap.value = result.error.format();
    toast.add({
      severity: 'error',
      detail: result.error.errors.map((_error) => `${_error.path[0]}: ${_error.message}`).join('\n'),
    });
    isSubmitting.value = false;
    return;
  }

  captureFeedback()
    .then(() => {
      emit('close');
    })
    .finally(() => {
      isSubmitting.value = false;
    });
}
</script>
