import {
  getShopLayoutView,
} from '@/generated/axios/openapi-pt9800/gui';
import {
  scaleFurnaces,
} from '@/pt9800/api/inputCleaning';

export const createModule = (services, location) => {
  const {
    getAllShoplayoutsItems,
  } = services.shopLayout;

  return {
    // initial state
    state: {
      layouts: {},
      layout: [],
      isLoading: false,
    },
    namespaced: true,

    getters: {
      getLayouts(state) {
        return state.layouts;
      },
      getFurnace: (state) => (workplaceId) => {
        const layouts = Object.values(state.layouts);
        for (const layout of layouts) {
          const result = layout.data.find(
            (obj) => obj.workplaceId === Number(workplaceId),
          );
          if (result) {
            return result;
          }
        }
        return null;
      },
    },

    actions: {
      async getAllShopLayouts({
        commit,
      }) {
        return getAllShoplayoutsItems(location, (layouts) => {
          commit('setLayouts', layouts);
          return layouts;
        });
      },

      async getLayout({
        commit,
      }, shopLayoutId) {
        commit('setIsLoading', true);
        try {
          const response = await getShopLayoutView({
            path: {
              ptDbId: location,
              shopWpId: shopLayoutId,
            },
          });
          commit('setLayout', response.data);
        } finally {
          commit('setIsLoading', false);
        }
      },

      async updateLayout({
        commit,
      }, shopLayoutId) {
        const response = await getShopLayoutView({
          path: {
            ptDbId: location,
            shopWpId: shopLayoutId,
          },
        });
        commit('setLayout', response.data);
      },

      async clearLayout({
        commit,
      }) {
        commit('clearLayout');
      },
    },

    // mutations
    mutations: {
      setLayouts(state, layouts) {
        for (const layout of layouts) {
          state.layouts[layout.id] = layout;
        }
      },
      /**
       * @param {*} state
       * @param {import('@/types/openapi_pt9800').components['schemas']['shopLayoutView']} shopLayoutView
       */
      setLayout(state, shopLayoutView) {
        let items = [
          {
            ...shopLayoutView,
            // hack from old times
            id: null,
            furnace: undefined,
            // TODO: hack, not yet migrated to REST api
            chargeCar: [],
          },
        ];
        shopLayoutView.furnace?.forEach((_furnace) => {
          items.push(_furnace);
        });
        items = scaleFurnaces(items);
        state.layout = items;
      },
      setIsLoading(state, isLoading) {
        state.isLoading = isLoading;
      },
      clearLayout(state) {
        state.layout = [];
      },
    },
  };
};
