import axios from 'axios';
import qs from 'qs';
import {
  refreshTokenIfNeeded,
} from 'axios-jwt';
import config from '@/config/config.js';
import {
  handleRequestRefresh, requestRefresh,
} from '@/utils/axios/auth-middleware';
import {
  Thunder as pt9800Thunder,
  apiFetch as pt9800ApiFetch,
} from '@/pt9800/generated/zeus';

const pt9800GraphqlClient: ReturnType<typeof pt9800Thunder> = pt9800Thunder(async (zeus, variables) => {
  const accessToken = await refreshTokenIfNeeded(requestRefresh);
  return pt9800ApiFetch([
    `${config.VITE_APP_API_PT9800_URL}/graphql`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'x-pt-location': variables!.location as string,
        'content-type': 'application/json',
      },
    },
  // @ts-ignore
  ])(zeus, variables);
});

const pt9800GraphqlClientQuery = pt9800GraphqlClient('query');
// const pt9800GraphqlClientMutation = pt9800GraphqlClient('mutation');

const axiosClientLegacy = axios.create({
  baseURL: config.VITE_APP_API_URL,
  withCredentials: true,
  paramsSerializer(params) {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
    });
  },
});

// has to be first request interceptor
axiosClientLegacy.interceptors.request.use(handleRequestRefresh);

const axiosClientV3 = axios.create({
  baseURL: `${config.VITE_APP_API_URL}/v3`,
  withCredentials: true,
  paramsSerializer(params) {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
    });
  },
});

// has to be first request interceptor
axiosClientV3.interceptors.request.use(handleRequestRefresh);

const axiosClientV3Npm = axios.create({
  baseURL: `${config.VITE_APP_API_URL}/v3/npm/api`,
  withCredentials: true,
  paramsSerializer(params) {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
    });
  },
});

// has to be first request interceptor
axiosClientV3Npm.interceptors.request.use(handleRequestRefresh);

const axiosClientRestPT9800 = axios.create({
  baseURL: `${config.VITE_APP_API_PT9800_URL}/restApi`,
  withCredentials: true,
  paramsSerializer(params) {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
    });
  },
});

// has to be first request interceptor
axiosClientRestPT9800.interceptors.request.use(handleRequestRefresh);

const axiosClientPT9800 = axios.create({
  baseURL: `${config.VITE_APP_API_PT9800_URL}`,
  withCredentials: true,
  paramsSerializer(params) {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
    });
  },
});

// has to be first request interceptor
axiosClientPT9800.interceptors.request.use(handleRequestRefresh);

export default axiosClientV3;
export {
  pt9800GraphqlClient,
  pt9800GraphqlClientQuery,
  axiosClientLegacy,
  axiosClientV3,
  axiosClientV3Npm,
  axiosClientRestPT9800,
  axiosClientPT9800,
};
