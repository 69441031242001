import dayjs, {
  Dayjs,
} from 'dayjs';
import {
  Duration,
} from 'dayjs/plugin/duration';
import {
  useAppStore,
} from '@/store/pinia/app';
import {
  ELocalisationStoreDateFormat,
} from '@/types/localization-store.ts';

export enum EDateFormatAmerican {
  DD_MM_YYYY = 'MM/DD/YYYY',
  DD_MM = 'MM/DD',
  HH_MM = 'hh:mm a',
  HH_MM_SS = 'hh:mm:ss a',
}

export enum EDateFormatEuropian {
  DD_MM_YYYY = 'DD.MM.YYYY',
  DD_MM = 'DD.MM',
  HH_MM = 'HH:mm',
  HH_MM_SS = 'HH:mm:ss',
}

export const API_DAY_FORMAT = 'YYYY-MM-DD';
export const API_DAY_TIME_FORMAT = 'YYYY-MM-DD[T]HH:mm';

export function getFormatFromHours(hours: number, days?: number) {
  if (days !== undefined && Math.floor(days) > 0) {
    return 'D[d] HH:mm';
  }

  if (hours > 0) {
    return 'HH:mm:ss';
  }

  return 'mm:ss';
}

export function formatDayjsDate(dayjsDate: Dayjs, short: boolean = false) {
  const dateFormat = useAppStore().dayjsDateFormat;
  const locale = useAppStore().locale;
  const shorterTimeFormat = dateFormat.HH_MM;
  const timeFormat = dateFormat.HH_MM_SS;
  return short
    ? dayjsDate.locale(locale).format(`L, ${shorterTimeFormat}`)
    : dayjsDate.locale(locale).format(`ll, ${timeFormat}`);
}

export function formatDate(date: string | number | Date, short: boolean = false) {
  return formatDayjsDate(dayjs(date), short);
}

export function formatDuration(date: Dayjs) {
  return date.format(getFormatFromHours(date.hour()));
}

export function formatFromDuration(duration: Duration, config: { showSeconds?: boolean; showMilliseconds?: boolean } = {}) {
  const {
    showSeconds = false,
    showMilliseconds = false,
  } = config;
  const durationCopy = duration.asSeconds() >= 0 ? duration : dayjs.duration(Math.abs(duration.asMilliseconds()));
  const negativeSign = duration.asSeconds() < 0 ? '-' : '';
  const secondsTemplate = showSeconds ? ':ss' : '';
  const millisecondsTemplate = (showSeconds && showMilliseconds) ? '.SSS' : '';
  if (durationCopy.years() >= 1) {
    return durationCopy.format(`${negativeSign}Y[y] M[m] D[d] HH:mm${secondsTemplate}${millisecondsTemplate}`);
  }

  if (durationCopy.asDays() >= 31) {
    return durationCopy.format(`${negativeSign}M[m] D[d] HH:mm${secondsTemplate}${millisecondsTemplate}`);
  }

  if (durationCopy.asHours() >= 24) {
    return durationCopy.format(`${negativeSign}${Math.floor(duration.asDays())}[d] HH:mm${secondsTemplate}${millisecondsTemplate}`);
  }

  return durationCopy.format(`${negativeSign}HH:mm:ss${millisecondsTemplate}`);
}

export function formatFromDurationShort(duration: Duration) {
  const hours = duration.asHours();
  if (hours >= 1) {
    if (duration.minutes() === 0) {
      return duration.format('H[h]');
    }
    return duration.format('H[h] m[m]');
  }

  const minutes = duration.asMinutes();
  if (minutes >= 1) {
    if (duration.seconds() === 0) {
      return duration.format('m[m]');
    }
    return duration.format('m[m] s[s]');
  }

  const milliseconds = duration.milliseconds();
  if (duration.asSeconds() < 1) {
    return `${milliseconds.toFixed(0)}ms`;
  }

  if (milliseconds === 0) {
    return duration.format('s[s]');
  }

  return `${duration.format('s[s]')} ${milliseconds.toFixed(0)}ms`;
}

export function formatDurationInSeconds(durationInSeconds: number) {
  return formatFromDuration(dayjs.duration(durationInSeconds, 'seconds'));
}

export function getDayjsDateFormat(timeFormat: ELocalisationStoreDateFormat): typeof EDateFormatAmerican | typeof EDateFormatEuropian {
  switch (timeFormat) {
    case ELocalisationStoreDateFormat.HOURS_24: return EDateFormatEuropian;
    case ELocalisationStoreDateFormat.HOURS_12: return EDateFormatAmerican;
    default: return EDateFormatEuropian;
  }
}

export function formatMilliseconds(mills: number, showSeconds?: boolean, showMilliseconds?: boolean): string {
  const duration = dayjs.duration(mills);

  let result = `${
    duration.hours()
      .toString()
      .padStart(2, '0')
  }:${
    duration.minutes()
      .toString()
      .padStart(2, '0')
  }`;
  if (showSeconds) {
    result += `:${duration.seconds().toString().padStart(2, '0')}`;
  }

  if (showMilliseconds) {
    result += `:${duration.milliseconds().toString().padStart(4, '0')}`;
  }
  return result;
}
