<template>
  <prm-icon-field
    v-bind="fieldProps"
  >
    <prm-input-text
      v-bind="inputProps"
      :model-value="modelValue"
      :disabled="loading || disabled || inputProps?.disabled"
      :readonly="loading || readonly || inputProps?.readonly"
      :placeholder="placeholder"
      style="width: 100%"
      :class="{
        'p-invalid': !!invalid,
      }"
      @update:model-value="emit('update:modelValue', $event as string)"
    />
    <prm-input-icon
      v-if="loading"
      class="pi pi-spin pi-spinner"
      v-bind="iconProps"
    />
  </prm-icon-field>
  <span
    v-if="!!errorMessage"
    class="input-wrapper-error"
  >
    {{ errorMessage }}
  </span>
</template>

<script lang="ts" setup>
import PrmInputText from 'primevue/inputtext';
import PrmInputIcon from 'primevue/inputicon';
import PrmIconField from 'primevue/iconfield';

defineProps<{
  modelValue?: string | null,
  loading?: boolean,
  // is for convenience
  disabled?: boolean,
  // is for convenience
  invalid?: boolean,
  // is for convenience
  readonly?: boolean,
  placeholder?: string,
  errorMessage?: string,
  inputProps?: InstanceType<typeof PrmInputText>['$props'],
  fieldProps?: InstanceType<typeof PrmIconField>['$props'],
  iconProps?: InstanceType<typeof PrmInputIcon>['$props'],
}>();

// eslint-disable-next-line func-call-spacing, no-spaced-func
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void,
}>();
</script>
