import {
  TTranslationAppearanceForm,
} from '@/page/general-settings/appearance/appearance.ts';

const appearanceForm: TTranslationAppearanceForm = {
  language: {
    label: 'Sprache',
    tooltip: 'Ermöglicht es, die gewünschte Sprache für die Benutzeroberfläche auszuwählen.',
  },
  timeFormat: {
    label: 'Zeitformat',
    tooltip: 'Definiert wie Daten und Zeitstempel angezeigt werden.',
  },
  fontSize: {
    label: 'Seiteskalierung',
    tooltip: 'Ermöglicht die größe von Schrift und Bedienelement anzupassen.',
  },
  chartTimeFormat: {
    label: 'Diagrammzeitformat',
    tooltip: 'Legt fest, wie Daten und Zeitstempel auf der X-Achse der Diagramme angezeigt werden.',
  },
};

export default {
  subline: 'Ändere das Erscheinungsbild von Qmulus.',
  appearanceForm,
};
