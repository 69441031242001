import {
  ECustomRangeType,
  TAbsoluteTimeUnit,
  TRelativeTimeUnit,
} from '@/components/date-range-picker/date-range-picker.ts';

const timeUnits: Record<TRelativeTimeUnit | TAbsoluteTimeUnit, string> = {
  days: 'Day | Days',
  hours: 'Hour | Hours',
  isoWeek: 'Calendar week | Calendar weeks',
  minutes: 'Minute | Minutes',
  months: 'Month | Months',
  quarters: 'Quarter | Quarters',
  weeks: 'Week | Weeks',
  years: 'Year | Years',
};

const timeUnitsShort: Record<TRelativeTimeUnit | TAbsoluteTimeUnit, string> = {
  days: 'd',
  hours: 'h',
  isoWeek: 'CW',
  minutes: 'min',
  months: 'm',
  quarters: 'Q',
  weeks: 'w',
  years: 'y',
};

const header: Record<ECustomRangeType, string> = {
  [ECustomRangeType.ABSOLUTE]: 'Absolute',
  [ECustomRangeType.CUSTOM]: 'Custom',
  [ECustomRangeType.RELATIVE]: 'Relative',
};

export default {
  header,
  convertToCustom: 'Convert to custom',
  custom: 'Custom',
  currentWeek: 'current week',
  previousWeek: 'previous week',
  nextWeek: 'next week',
  calendarWeek: 'CW',
  rangeInvalidHint: 'The selected time period is either greater than the maximum allowable duration {maxDuration} or the chosen dates are outside the permitted range of {minDate} - {maxDate}.',
  rangeFrom: 'From',
  rangeTo: 'To',
  timeUnit: 'Time unit',
  timeUnits,
  timeUnitsShort,
};
