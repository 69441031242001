<template>
  <qmulus-button
    :label="pictureDataMap.imageUrl ? pictureDataMap.displayName : buttonLabel"
    :loading="loading"
    :severity="pictureDataMap.imageUrl ? 'danger' : 'tertiary'"
    :icon="pictureDataMap.imageUrl ? 'pi pi-times' : 'pi pi-upload'"
    @click="action"
  />
  <modal-upload
    v-if="showModalUpload"
    :picture-data="pictureDataMap"
    :show-description-input="showDescriptionInput"
    @close="showModalUpload = false"
    @submit="emit('submit', $event); pictureDataMap = $event; showModalUpload = false;"
  />
</template>

<script lang="ts" setup>
import {
  ref,
  shallowRef,
} from 'vue';
import {
  i18n,
} from '@i18n';
import QmulusButton from '@/components/button/qmulus-button.vue';
import ModalUpload from '@/components/upload-picture/modal-upload.vue';
import {
  DEFAULT_PICTURE_DATA,
  TPictureData,
} from '@/components/upload-picture/modal-upload.ts';

const I18N_PREFIX = 'components/upload-picture/upload-picture';

const props = defineProps<{
  label?: string,
  loading?: boolean,
  showDescriptionInput?: boolean,
}>();

// eslint-disable-next-line func-call-spacing, no-spaced-func
const emit = defineEmits<{
  (e: 'submit', value: TPictureData): void,
}>();

const buttonLabel = shallowRef(props.label || i18n.global.t(`${I18N_PREFIX}.addScreenshot`));

const pictureDataMap = ref<TPictureData>(JSON.parse(JSON.stringify(DEFAULT_PICTURE_DATA)));

const showModalUpload = shallowRef(false);

function action() {
  if (!pictureDataMap.value.imageUrl) {
    showModalUpload.value = true;
    return;
  }
  pictureDataMap.value = JSON.parse(JSON.stringify(DEFAULT_PICTURE_DATA));
}
</script>
