export type TPictureData = {
  displayName: string,
  imageUrl: string | null,
  file: File | null,
  description: string,
}

export const DEFAULT_PICTURE_DATA: TPictureData = {
  displayName: '',
  imageUrl: null,
  file: null,
  description: '',
};
