import {
  components,
} from '@/types/openapi.ts';

// @deprecated
export enum EMeasurementUnitSystem {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

// @deprecated
export enum EPhysicalDimension {
  FLOW = 'flow',
  LENGTH = 'length',
  TIME = 'time',
  TEMPERATURE = 'temperature',
}

// @deprecated
export enum EMeasurementUnit {
  DEGREES_CELSIUS = '°C',
  DEGREES_FAHRENHEIT = '°F',
  HARDNESS_VICKERS = 'HV',
  INCH = 'in',
  MICRON = 'µm',
  MINUTE = 'min',
  LITRE_PER_MINUTE = 'l/min',
}

export enum EMeasurementLabel {
  UNIT_LESS = 'N/A',
  PERCENT = '%',
  PARTS_PER_MILLION = 'ppm',
  DEGREES_CELSIUS = '°C',
  DEGREES_FAHRENHEIT = '°F',
  DEGREES_KELVIN = 'K',
  DELTA_DEGREES_CELSIUS = 'K',
  DELTA_DEGREES_FAHRENHEIT = 'Δ°F',
  DELTA_DEGREES_KELVIN = 'K',
  PASCAL = 'Pa',
  KILO_PASCAL = 'kPa',
  BAR = 'bar',
  MILLIBAR = 'mbar',
  MICROBAR = 'μbar',
  PSI = 'psi',
  INCH_OF_WATER_COLUMN = 'inH2O',
  INCH_OF_MERCURY = 'inHg',
  MILLIMETRE_OF_WATER_COLUMN = 'mmH2O',
  MILLIMETRE_OF_MERCURY = 'mmHg',
  FEET_OF_WATER_COLUMN = 'ftH2O',
  TORR = 'Torr',
  MICRON_OF_MERCURY = 'micron (μmHg)',
  MILLILITRE_PER_MINUTE = 'ml/min',
  LITRE_PER_MINUTE = 'l/min',
  LITRE_PER_HOUR = 'l/h',
  CUBIC_CENTIMETRE_PER_MINUTE = 'cc/min',
  CUBIC_FEET_PER_MINUTE = 'cfm',
  CUBIC_FEET_PER_HOUR = 'cfh',
  CUBIC_METRE_PER_MINUTE = 'm³/min',
  CUBIC_METRE_PER_HOUR = 'm³/h',
  GALLONS_PER_MINUTE = 'gpm',
  GALLONS_PER_HOUR = 'gph',
  AMPERE = 'A',
  MILLI_AMPERE = 'mA',
  VOLT = 'V',
  MILLIVOLT = 'mV',
  KILOVOLT = 'kV',
  WATT = 'W',
  MEGAWATT = 'MW',
  KILOWATT = 'kW',
  VOLT_AMPERE = 'VA',
  KILOVOLT_AMPERE = 'kVA',
  BTU_PER_MINUTE = 'BTU/min',
  HORSEPOWER = 'hp',
  KILOWATT_HOUR = 'kWh',
  BTU = 'BTU',
  METRES_PER_SECOND = 'm/s',
  METRES_PER_MINUTE = 'm/min',
  RPM = '1/min',
  NUMBER = '#',
  DEGREES_CELSIUS_PER_MINUTE = '°C/min',
  DEGREES_FAHRENHEIT_PER_MINUTE = '°F/min',
  SECOND = 's',
  MINUTE = 'min',
  HOUR = 'h',
  BAR_PER_MINUTE = 'bar/min',
  MILLIBAR_PER_MINUTE = 'mbar/min',
  MICROBAR_PER_MINUTE = 'μbar/min',
  TORR_PER_MINUTE = 'Torr/min',
  MICRON_HG_PER_MINUTE = 'micron (μmHg)/min',
  MICRON_HG_PER_HOUR = 'μmHg/h',
  TONNE = 't',
  KILOGRAM = 'kg',
  GRAM = 'g',
  MILLIGRAM = 'mg',
  POUND = 'lb',
  KILOMETER = 'km',
  METRE = 'm',
  MILLIMETRE = 'mm',
  MICRON = 'μm',
  INCH = 'in',
  MILLILITRE = 'ml',
  LITRE = 'l',
  CUBIC_METRES = 'm³',
  CUBIC_FEET = 'ft³',
  ATMOSPHERE = 'atm',
  WEIGHT_PERCENT = 'wt%',
  CUBIC_METRE_PER_SECOND = 'm³/s',
  PERCENT_CARBON = '%C',
  PERCENT_NITROGEN = '%N',
  PERCENT_HYDROGEN = '%H',
  BTU_PER_HOUR = 'BTU/h',
  DAY = 'day(s)',
  WEEK = 'week(s)',
  MONTH = 'month(s)',
  YEAR = 'year(s)',
  VICKERS_HARDNESS = 'HV',
  VICKERS_HARDNESS_0_1 = 'HV/0.1',
  VICKERS_HARDNESS_0_2 = 'HV/0.2',
  VICKERS_HARDNESS_0_3 = 'HV/0.3',
  VICKERS_HARDNESS_0_4 = 'HV/0.4',
  VICKERS_HARDNESS_0_5 = 'HV/0.5',
  VICKERS_HARDNESS_0_6 = 'HV/0.6',
  VICKERS_HARDNESS_0_7 = 'HV/0.7',
  VICKERS_HARDNESS_0_8 = 'HV/0.8',
  VICKERS_HARDNESS_0_9 = 'HV/0.9',
  VICKERS_HARDNESS_1 = 'HV/1',
  VICKERS_HARDNESS_10 = 'HV/10',
  VICKERS_HARDNESS_20 = 'HV/20',
  VICKERS_HARDNESS_30 = 'HV/30',
  VICKERS_HARDNESS_40 = 'HV/40',
  VICKERS_HARDNESS_50 = 'HV/50',
  VICKERS_HARDNESS_60 = 'HV/60',
  VICKERS_HARDNESS_70 = 'HV/70',
  VICKERS_HARDNESS_80 = 'HV/80',
  VICKERS_HARDNESS_90 = 'HV/90',
  VICKERS_HARDNESS_100 = 'HV/100',
  ROCKWELL_HARDNESS = 'HR',
  ROCKWELL_HARDNESS_A = 'HRA',
  ROCKWELL_HARDNESS_B = 'HRB',
  ROCKWELL_HARDNESS_C = 'HRC',
  ROCKWELL_HARDNESS_D = 'HRD',
  ROCKWELL_HARDNESS_E = 'HRE',
  ROCKWELL_HARDNESS_F = 'HRF',
  ROCKWELL_HARDNESS_G = 'HRG',
  ROCKWELL_HARDNESS_H = 'HRH',
  ROCKWELL_HARDNESS_K = 'HRK',
  ROCKWELL_HARDNESS_L = 'HRL',
  ROCKWELL_HARDNESS_M = 'HRM',
  ROCKWELL_HARDNESS_P = 'HRP',
  ROCKWELL_HARDNESS_R = 'HRR',
  ROCKWELL_HARDNESS_S = 'HRS',
  ROCKWELL_HARDNESS_V = 'HRV',
  ROCKWELL_HARDNESS_15_N = 'HR 15 N',
  ROCKWELL_HARDNESS_30_N = 'HR 30 N',
  ROCKWELL_HARDNESS_45_N = 'HR 45 N',
  ROCKWELL_HARDNESS_15_T = 'HR 15 T',
  ROCKWELL_HARDNESS_30_T = 'HR 30 T',
  ROCKWELL_HARDNESS_45_T = 'HR 45 T',
  ROCKWELL_HARDNESS_15_W = 'HR 15 W',
  ROCKWELL_HARDNESS_30_W = 'HR 30 W',
  ROCKWELL_HARDNESS_45_W = 'HR 45 W',
  ROCKWELL_HARDNESS_15_X = 'HR 15 X',
  ROCKWELL_HARDNESS_30_X = 'HR 30 X',
  ROCKWELL_HARDNESS_45_X = 'HR 45 X',
  ROCKWELL_HARDNESS_15_Y = 'HR 15 Y',
  ROCKWELL_HARDNESS_30_Y = 'HR 30 Y',
  ROCKWELL_HARDNESS_45_Y = 'HR 45 Y',
  BRINELL_HARDNESS = 'BHN',
  BRINELL_HARDNESS_10_500_15 = 'HB 10/500/15',
  BRINELL_HARDNESS_10_1500_15 = 'HB 10/1500/15',
  BRINELL_HARDNESS_10_3000_15 = 'HB 10/3000/15',
  BRINELL_HARDNESS_10_500_10 = 'HB 10/500/10',
  BRINELL_HARDNESS_10_1500_10 = 'HB 10/1500/10',
  BRINELL_HARDNESS_10_3000_10 = 'HB 10/3000/10',
  BRINELL_HARDNESS_6_500_15 = 'HB 6/500/15',
  BRINELL_HARDNESS_6_1500_15 = 'HB 6/1500/15"',
  BRINELL_HARDNESS_6_3000_15 = 'HB 6/3000/15"',
  BRINELL_HARDNESS_6_500_10 = 'HB 6/500/10"',
  BRINELL_HARDNESS_6_1500_10 = 'HB 6/1500/10"',
  BRINELL_HARDNESS_6_3000_10 = 'HB 6/3000/10"',
  KNOOP_HARDNESS = 'HK',
  KNOOP_HARDNESS_200 = 'HK 200',
  KNOOP_HARDNESS_500 = 'HK 500',
  KNOOP_HARDNESS_50 = 'HK 50',
  KNOOP_HARDNESS_100 = 'HK 100',
  NORMAL_LITRE_PER_SQUARE_METERS_PER_HOUR = 'nl/m/h²',
  MILLIMETRE_PER_SECOND = 'mm/s',
  KILOGRAM_PER_HOUR = 'kg/s',
  KILOGRAM_PER_MINUTE = 'kg/min',
  KILOGRAM_PER_SECOND = 'kg/s',
  GRAM_PER_SECOND = 'g/s',
  HECTO_PASCAL = 'hPa',
  MILLITORR_PER_HOUR = 'mTorr/h',
}

// Ensures that each unit as a symbol
export const UnitSymbolMap: Record<components['schemas']['UnitOfMeasurementType'], keyof typeof EMeasurementLabel> = {
  0: 'UNIT_LESS',
  1: 'PERCENT',
  2: 'PARTS_PER_MILLION',
  3: 'DEGREES_CELSIUS',
  4: 'DEGREES_FAHRENHEIT',
  5: 'DEGREES_KELVIN',
  6: 'DELTA_DEGREES_CELSIUS',
  7: 'DELTA_DEGREES_FAHRENHEIT',
  8: 'DELTA_DEGREES_KELVIN',
  9: 'PASCAL',
  10: 'KILO_PASCAL',
  11: 'BAR',
  12: 'MILLIBAR',
  13: 'MICROBAR',
  14: 'PSI',
  15: 'INCH_OF_WATER_COLUMN',
  16: 'INCH_OF_MERCURY',
  17: 'MILLIMETRE_OF_WATER_COLUMN',
  18: 'MILLIMETRE_OF_MERCURY',
  19: 'FEET_OF_WATER_COLUMN',
  20: 'TORR',
  21: 'MICRON_OF_MERCURY',
  22: 'MILLILITRE_PER_MINUTE',
  23: 'LITRE_PER_MINUTE',
  24: 'LITRE_PER_HOUR',
  25: 'CUBIC_CENTIMETRE_PER_MINUTE',
  26: 'CUBIC_FEET_PER_MINUTE',
  27: 'CUBIC_FEET_PER_HOUR',
  28: 'CUBIC_METRE_PER_MINUTE',
  29: 'CUBIC_METRE_PER_HOUR',
  30: 'GALLONS_PER_MINUTE',
  31: 'GALLONS_PER_HOUR',
  32: 'AMPERE',
  33: 'MILLI_AMPERE',
  34: 'VOLT',
  35: 'MILLIVOLT',
  36: 'KILOVOLT',
  37: 'WATT',
  38: 'MEGAWATT',
  39: 'KILOWATT',
  40: 'VOLT_AMPERE',
  41: 'KILOVOLT_AMPERE',
  42: 'BTU_PER_MINUTE',
  43: 'HORSEPOWER',
  44: 'KILOWATT_HOUR',
  45: 'BTU',
  46: 'METRES_PER_SECOND',
  47: 'METRES_PER_MINUTE',
  48: 'RPM',
  49: 'NUMBER',
  50: 'DEGREES_CELSIUS_PER_MINUTE',
  51: 'DEGREES_FAHRENHEIT_PER_MINUTE',
  52: 'SECOND',
  53: 'MINUTE',
  54: 'HOUR',
  55: 'BAR_PER_MINUTE',
  56: 'MILLIBAR_PER_MINUTE',
  57: 'MICROBAR_PER_MINUTE',
  58: 'TORR_PER_MINUTE',
  59: 'MICRON_HG_PER_MINUTE',
  60: 'MICRON_HG_PER_HOUR',
  61: 'TONNE',
  62: 'KILOGRAM',
  63: 'GRAM',
  64: 'MILLIGRAM',
  65: 'POUND',
  66: 'KILOMETER',
  67: 'METRE',
  68: 'MILLIMETRE',
  69: 'MICRON',
  70: 'INCH',
  71: 'MILLILITRE',
  72: 'LITRE',
  73: 'CUBIC_METRES',
  74: 'CUBIC_FEET',
  75: 'ATMOSPHERE',
  76: 'WEIGHT_PERCENT',
  77: 'CUBIC_METRE_PER_SECOND',
  78: 'PERCENT_CARBON',
  79: 'PERCENT_NITROGEN',
  80: 'BTU_PER_HOUR',
  81: 'DAY',
  82: 'WEEK',
  83: 'MONTH',
  84: 'YEAR',
  85: 'PERCENT_HYDROGEN',
  86: 'VICKERS_HARDNESS',
  87: 'VICKERS_HARDNESS_0_1',
  88: 'VICKERS_HARDNESS_0_2',
  89: 'VICKERS_HARDNESS_0_3',
  90: 'VICKERS_HARDNESS_0_4',
  91: 'VICKERS_HARDNESS_0_5',
  92: 'VICKERS_HARDNESS_0_6',
  93: 'VICKERS_HARDNESS_0_7',
  94: 'VICKERS_HARDNESS_0_8',
  95: 'VICKERS_HARDNESS_0_9',
  96: 'VICKERS_HARDNESS_1',
  97: 'VICKERS_HARDNESS_10',
  98: 'VICKERS_HARDNESS_20',
  99: 'VICKERS_HARDNESS_30',
  100: 'VICKERS_HARDNESS_40',
  101: 'VICKERS_HARDNESS_50',
  102: 'VICKERS_HARDNESS_60',
  103: 'VICKERS_HARDNESS_70',
  104: 'VICKERS_HARDNESS_80',
  105: 'VICKERS_HARDNESS_90',
  106: 'VICKERS_HARDNESS_100',
  107: 'ROCKWELL_HARDNESS',
  108: 'ROCKWELL_HARDNESS_A',
  109: 'ROCKWELL_HARDNESS_B',
  110: 'ROCKWELL_HARDNESS_C',
  111: 'ROCKWELL_HARDNESS_D',
  112: 'ROCKWELL_HARDNESS_E',
  113: 'ROCKWELL_HARDNESS_F',
  114: 'ROCKWELL_HARDNESS_G',
  115: 'ROCKWELL_HARDNESS_H',
  116: 'ROCKWELL_HARDNESS_K',
  117: 'ROCKWELL_HARDNESS_L',
  118: 'ROCKWELL_HARDNESS_M',
  119: 'ROCKWELL_HARDNESS_P',
  120: 'ROCKWELL_HARDNESS_R',
  121: 'ROCKWELL_HARDNESS_S',
  122: 'ROCKWELL_HARDNESS_V',
  123: 'ROCKWELL_HARDNESS_15_N',
  124: 'ROCKWELL_HARDNESS_30_N',
  125: 'ROCKWELL_HARDNESS_45_N',
  126: 'ROCKWELL_HARDNESS_15_T',
  127: 'ROCKWELL_HARDNESS_30_T',
  128: 'ROCKWELL_HARDNESS_45_T',
  129: 'ROCKWELL_HARDNESS_15_W',
  130: 'ROCKWELL_HARDNESS_30_W',
  131: 'ROCKWELL_HARDNESS_45_W',
  132: 'ROCKWELL_HARDNESS_15_X',
  133: 'ROCKWELL_HARDNESS_30_X',
  134: 'ROCKWELL_HARDNESS_45_X',
  135: 'ROCKWELL_HARDNESS_15_Y',
  136: 'ROCKWELL_HARDNESS_30_Y',
  137: 'ROCKWELL_HARDNESS_45_Y',
  138: 'BRINELL_HARDNESS',
  139: 'BRINELL_HARDNESS_10_500_15',
  140: 'BRINELL_HARDNESS_10_1500_15',
  141: 'BRINELL_HARDNESS_10_3000_15',
  142: 'BRINELL_HARDNESS_10_500_10',
  143: 'BRINELL_HARDNESS_10_1500_10',
  144: 'BRINELL_HARDNESS_10_3000_10',
  145: 'BRINELL_HARDNESS_6_500_15',
  146: 'BRINELL_HARDNESS_6_1500_15',
  147: 'BRINELL_HARDNESS_6_3000_15',
  148: 'BRINELL_HARDNESS_6_500_10',
  149: 'BRINELL_HARDNESS_6_1500_10',
  150: 'BRINELL_HARDNESS_6_3000_10',
  151: 'KNOOP_HARDNESS',
  152: 'KNOOP_HARDNESS_200',
  153: 'KNOOP_HARDNESS_500',
  154: 'KNOOP_HARDNESS_50',
  155: 'KNOOP_HARDNESS_100',
  156: 'NORMAL_LITRE_PER_SQUARE_METERS_PER_HOUR',
  157: 'MILLIMETRE_PER_SECOND',
  158: 'KILOGRAM_PER_HOUR',
  159: 'KILOGRAM_PER_MINUTE',
  160: 'KILOGRAM_PER_SECOND',
  161: 'GRAM_PER_SECOND',
  162: 'HECTO_PASCAL',
  163: 'MILLITORR_PER_HOUR',
};

export enum EMeasurementUnitType {
  UNIT_LESS = 0,
  PERCENT = 1,
  PARTS_PER_MILLION = 2,
  DEGREES_CELSIUS = 3,
  DEGREES_FAHRENHEIT = 4,
  DEGREES_KELVIN = 5,
  DELTA_DEGREES_CELSIUS = 6,
  DELTA_DEGREES_FAHRENHEIT = 7,
  DELTA_DEGREES_KELVIN = 8,
  PASCAL = 9,
  KILO_PASCAL = 10,
  BAR = 11,
  MILLIBAR = 12,
  MICROBAR = 13,
  PSI = 14,
  INCH_OF_WATER_COLUMN = 15,
  INCH_OF_MERCURY = 16,
  MILLIMETRE_OF_WATER_COLUMN = 17,
  MILLIMETRE_OF_MERCURY = 18,
  FEET_OF_WATER_COLUMN = 19,
  TORR = 20,
  MICRON_OF_MERCURY = 21,
  MILLILITRE_PER_MINUTE = 22,
  LITRE_PER_MINUTE = 23,
  LITRE_PER_HOUR = 24,
  CUBIC_CENTIMETRE_PER_MINUTE = 25,
  CUBIC_FEET_PER_MINUTE = 26,
  CUBIC_FEET_PER_HOUR = 27,
  CUBIC_METRE_PER_MINUTE = 28,
  CUBIC_METRE_PER_HOUR = 29,
  GALLONS_PER_MINUTE = 30,
  GALLONS_PER_HOUR = 31,
  AMPERE = 32,
  MILLI_AMPERE = 33,
  VOLT = 34,
  MILLIVOLT = 35,
  KILOVOLT = 36,
  WATT = 37,
  MEGAWATT = 38,
  KILOWATT = 39,
  VOLT_AMPERE = 40,
  KILOVOLT_AMPERE = 41,
  BTU_PER_MINUTE = 42,
  HORSEPOWER = 43,
  KILOWATT_HOUR = 44,
  BTU = 45,
  METRES_PER_SECOND = 46,
  METRES_PER_MINUTE =47,
  RPM = 48,
  NUMBER = 49,
  DEGREES_CELSIUS_PER_MINUTE = 50,
  DEGREES_FAHRENHEIT_PER_MINUTE = 51,
  SECOND = 52,
  MINUTE = 53,
  HOUR = 54,
  BAR_PER_MINUTE = 55,
  MILLIBAR_PER_MINUTE = 56,
  MICROBAR_PER_MINUTE = 57,
  TORR_PER_MINUTE = 58,
  MICRON_HG_PER_MINUTE = 59,
  MICRON_HG_PER_HOUR = 60,
  TONNE = 61,
  KILOGRAM = 62,
  GRAM = 63,
  MILLIGRAM = 64,
  POUND = 65,
  KILOMETER = 66,
  METRE = 67,
  MILLIMETRE = 68,
  MICRON = 69,
  INCH = 70,
  MILLILITRE = 71,
  LITRE = 72,
  CUBIC_METRES = 73,
  CUBIC_FEET = 74,
  ATMOSPHERE = 75,
  WEIGHT_PERCENT = 76,
  CUBIC_METRE_PER_SECOND = 77,
  PERCENT_CARBON = 78,
  PERCENT_NITROGEN = 79,
  BTU_PER_HOUR = 80,
  DAY = 81,
  WEEK = 82,
  MONTH = 83,
  YEAR = 84,
  PERCENT_HYDROGEN = 85,
  VICKERS_HARDNESS = 86,
  VICKERS_HARDNESS_0_1 = 87,
  VICKERS_HARDNESS_0_2 = 88,
  VICKERS_HARDNESS_0_3 = 89,
  VICKERS_HARDNESS_0_4 = 90,
  VICKERS_HARDNESS_0_5 = 91,
  VICKERS_HARDNESS_0_6 = 92,
  VICKERS_HARDNESS_0_7 = 93,
  VICKERS_HARDNESS_0_8 = 94,
  VICKERS_HARDNESS_0_9 = 95,
  VICKERS_HARDNESS_1 = 96,
  VICKERS_HARDNESS_10 = 97,
  VICKERS_HARDNESS_20 = 98,
  VICKERS_HARDNESS_30 = 99,
  VICKERS_HARDNESS_40 = 100,
  VICKERS_HARDNESS_50 = 101,
  VICKERS_HARDNESS_60 = 102,
  VICKERS_HARDNESS_70 = 103,
  VICKERS_HARDNESS_80 = 104,
  VICKERS_HARDNESS_90 = 105,
  VICKERS_HARDNESS_100 = 106,
  ROCKWELL_HARDNESS = 107,
  ROCKWELL_HARDNESS_A = 108,
  ROCKWELL_HARDNESS_B = 109,
  ROCKWELL_HARDNESS_C = 110,
  ROCKWELL_HARDNESS_D = 111,
  ROCKWELL_HARDNESS_E = 112,
  ROCKWELL_HARDNESS_F = 113,
  ROCKWELL_HARDNESS_G = 114,
  ROCKWELL_HARDNESS_H = 115,
  ROCKWELL_HARDNESS_K = 116,
  ROCKWELL_HARDNESS_L = 117,
  ROCKWELL_HARDNESS_M = 118,
  ROCKWELL_HARDNESS_P = 119,
  ROCKWELL_HARDNESS_R = 120,
  ROCKWELL_HARDNESS_S = 121,
  ROCKWELL_HARDNESS_V = 122,
  ROCKWELL_HARDNESS_15_N = 123,
  ROCKWELL_HARDNESS_30_N = 124,
  ROCKWELL_HARDNESS_45_N = 125,
  ROCKWELL_HARDNESS_15_T = 126,
  ROCKWELL_HARDNESS_30_T = 127,
  ROCKWELL_HARDNESS_45_T = 128,
  ROCKWELL_HARDNESS_15_W = 129,
  ROCKWELL_HARDNESS_30_W = 130,
  ROCKWELL_HARDNESS_45_W = 131,
  ROCKWELL_HARDNESS_15_X = 132,
  ROCKWELL_HARDNESS_30_X = 133,
  ROCKWELL_HARDNESS_45_X = 134,
  ROCKWELL_HARDNESS_15_Y = 135,
  ROCKWELL_HARDNESS_30_Y = 136,
  ROCKWELL_HARDNESS_45_Y = 137,
  BRINELL_HARDNESS = 138,
  BRINELL_HARDNESS_10_500_15 = 139,
  BRINELL_HARDNESS_10_1500_15 = 140,
  BRINELL_HARDNESS_10_3000_15 = 141,
  BRINELL_HARDNESS_10_500_10 = 142,
  BRINELL_HARDNESS_10_1500_10 = 143,
  BRINELL_HARDNESS_10_3000_10 = 144,
  BRINELL_HARDNESS_6_500_15 = 145,
  BRINELL_HARDNESS_6_1500_15 = 146,
  BRINELL_HARDNESS_6_3000_15 = 147,
  BRINELL_HARDNESS_6_500_10 = 148,
  BRINELL_HARDNESS_6_1500_10 = 149,
  BRINELL_HARDNESS_6_3000_10 = 150,
  KNOOP_HARDNESS = 151,
  KNOOP_HARDNESS_200 = 152,
  KNOOP_HARDNESS_500 = 153,
  KNOOP_HARDNESS_50 = 154,
  KNOOP_HARDNESS_100 = 155,
  NORMAL_LITRE_PER_SQUARE_METERS_PER_HOUR = 156,
  MILLIMETRE_PER_SECOND = 157,
  KILOGRAM_PER_HOUR = 158,
  KILOGRAM_PER_MINUTE = 159,
  KILOGRAM_PER_SECOND = 160,
  GRAM_PER_SECOND = 161,
  HECTO_PASCAL = 162,
  MILLITORR_PER_HOUR = 163,
}

export const MeasurementUnitMap: Record<components['schemas']['UnitOfMeasurementType'], keyof typeof EMeasurementUnitType> = {
  0: 'UNIT_LESS',
  1: 'PERCENT',
  2: 'PARTS_PER_MILLION',
  3: 'DEGREES_CELSIUS',
  4: 'DEGREES_FAHRENHEIT',
  5: 'DEGREES_KELVIN',
  6: 'DELTA_DEGREES_CELSIUS',
  7: 'DELTA_DEGREES_FAHRENHEIT',
  8: 'DELTA_DEGREES_KELVIN',
  9: 'PASCAL',
  10: 'KILO_PASCAL',
  11: 'BAR',
  12: 'MILLIBAR',
  13: 'MICROBAR',
  14: 'PSI',
  15: 'INCH_OF_WATER_COLUMN',
  16: 'INCH_OF_MERCURY',
  17: 'MILLIMETRE_OF_WATER_COLUMN',
  18: 'MILLIMETRE_OF_MERCURY',
  19: 'FEET_OF_WATER_COLUMN',
  20: 'TORR',
  21: 'MICRON_OF_MERCURY',
  22: 'MILLILITRE_PER_MINUTE',
  23: 'LITRE_PER_MINUTE',
  24: 'LITRE_PER_HOUR',
  25: 'CUBIC_CENTIMETRE_PER_MINUTE',
  26: 'CUBIC_FEET_PER_MINUTE',
  27: 'CUBIC_FEET_PER_HOUR',
  28: 'CUBIC_METRE_PER_MINUTE',
  29: 'CUBIC_METRE_PER_HOUR',
  30: 'GALLONS_PER_MINUTE',
  31: 'GALLONS_PER_HOUR',
  32: 'AMPERE',
  33: 'MILLI_AMPERE',
  34: 'VOLT',
  35: 'MILLIVOLT',
  36: 'KILOVOLT',
  37: 'WATT',
  38: 'MEGAWATT',
  39: 'KILOWATT',
  40: 'VOLT_AMPERE',
  41: 'KILOVOLT_AMPERE',
  42: 'BTU_PER_MINUTE',
  43: 'HORSEPOWER',
  44: 'KILOWATT_HOUR',
  45: 'BTU',
  46: 'METRES_PER_SECOND',
  47: 'METRES_PER_MINUTE',
  48: 'RPM',
  49: 'NUMBER',
  50: 'DEGREES_CELSIUS_PER_MINUTE',
  51: 'DEGREES_FAHRENHEIT_PER_MINUTE',
  52: 'SECOND',
  53: 'MINUTE',
  54: 'HOUR',
  55: 'BAR_PER_MINUTE',
  56: 'MILLIBAR_PER_MINUTE',
  57: 'MICROBAR_PER_MINUTE',
  58: 'TORR_PER_MINUTE',
  59: 'MICRON_HG_PER_MINUTE',
  60: 'MICRON_HG_PER_HOUR',
  61: 'TONNE',
  62: 'KILOGRAM',
  63: 'GRAM',
  64: 'MILLIGRAM',
  65: 'POUND',
  66: 'KILOMETER',
  67: 'METRE',
  68: 'MILLIMETRE',
  69: 'MICRON',
  70: 'INCH',
  71: 'MILLILITRE',
  72: 'LITRE',
  73: 'CUBIC_METRES',
  74: 'CUBIC_FEET',
  75: 'ATMOSPHERE',
  76: 'WEIGHT_PERCENT',
  77: 'CUBIC_METRE_PER_SECOND',
  78: 'PERCENT_CARBON',
  79: 'PERCENT_NITROGEN',
  80: 'BTU_PER_HOUR',
  81: 'DAY',
  82: 'WEEK',
  83: 'MONTH',
  84: 'YEAR',
  85: 'PERCENT_HYDROGEN',
  86: 'VICKERS_HARDNESS',
  87: 'VICKERS_HARDNESS_0_1',
  88: 'VICKERS_HARDNESS_0_2',
  89: 'VICKERS_HARDNESS_0_3',
  90: 'VICKERS_HARDNESS_0_4',
  91: 'VICKERS_HARDNESS_0_5',
  92: 'VICKERS_HARDNESS_0_6',
  93: 'VICKERS_HARDNESS_0_7',
  94: 'VICKERS_HARDNESS_0_8',
  95: 'VICKERS_HARDNESS_0_9',
  96: 'VICKERS_HARDNESS_1',
  97: 'VICKERS_HARDNESS_10',
  98: 'VICKERS_HARDNESS_20',
  99: 'VICKERS_HARDNESS_30',
  100: 'VICKERS_HARDNESS_40',
  101: 'VICKERS_HARDNESS_50',
  102: 'VICKERS_HARDNESS_60',
  103: 'VICKERS_HARDNESS_70',
  104: 'VICKERS_HARDNESS_80',
  105: 'VICKERS_HARDNESS_90',
  106: 'VICKERS_HARDNESS_100',
  107: 'ROCKWELL_HARDNESS',
  108: 'ROCKWELL_HARDNESS_A',
  109: 'ROCKWELL_HARDNESS_B',
  110: 'ROCKWELL_HARDNESS_C',
  111: 'ROCKWELL_HARDNESS_D',
  112: 'ROCKWELL_HARDNESS_E',
  113: 'ROCKWELL_HARDNESS_F',
  114: 'ROCKWELL_HARDNESS_G',
  115: 'ROCKWELL_HARDNESS_H',
  116: 'ROCKWELL_HARDNESS_K',
  117: 'ROCKWELL_HARDNESS_L',
  118: 'ROCKWELL_HARDNESS_M',
  119: 'ROCKWELL_HARDNESS_P',
  120: 'ROCKWELL_HARDNESS_R',
  121: 'ROCKWELL_HARDNESS_S',
  122: 'ROCKWELL_HARDNESS_V',
  123: 'ROCKWELL_HARDNESS_15_N',
  124: 'ROCKWELL_HARDNESS_30_N',
  125: 'ROCKWELL_HARDNESS_45_N',
  126: 'ROCKWELL_HARDNESS_15_T',
  127: 'ROCKWELL_HARDNESS_30_T',
  128: 'ROCKWELL_HARDNESS_45_T',
  129: 'ROCKWELL_HARDNESS_15_W',
  130: 'ROCKWELL_HARDNESS_30_W',
  131: 'ROCKWELL_HARDNESS_45_W',
  132: 'ROCKWELL_HARDNESS_15_X',
  133: 'ROCKWELL_HARDNESS_30_X',
  134: 'ROCKWELL_HARDNESS_45_X',
  135: 'ROCKWELL_HARDNESS_15_Y',
  136: 'ROCKWELL_HARDNESS_30_Y',
  137: 'ROCKWELL_HARDNESS_45_Y',
  138: 'BRINELL_HARDNESS',
  139: 'BRINELL_HARDNESS_10_500_15',
  140: 'BRINELL_HARDNESS_10_1500_15',
  141: 'BRINELL_HARDNESS_10_3000_15',
  142: 'BRINELL_HARDNESS_10_500_10',
  143: 'BRINELL_HARDNESS_10_1500_10',
  144: 'BRINELL_HARDNESS_10_3000_10',
  145: 'BRINELL_HARDNESS_6_500_15',
  146: 'BRINELL_HARDNESS_6_1500_15',
  147: 'BRINELL_HARDNESS_6_3000_15',
  148: 'BRINELL_HARDNESS_6_500_10',
  149: 'BRINELL_HARDNESS_6_1500_10',
  150: 'BRINELL_HARDNESS_6_3000_10',
  151: 'KNOOP_HARDNESS',
  152: 'KNOOP_HARDNESS_200',
  153: 'KNOOP_HARDNESS_500',
  154: 'KNOOP_HARDNESS_50',
  155: 'KNOOP_HARDNESS_100',
  156: 'NORMAL_LITRE_PER_SQUARE_METERS_PER_HOUR',
  157: 'MILLIMETRE_PER_SECOND',
  158: 'KILOGRAM_PER_HOUR',
  159: 'KILOGRAM_PER_MINUTE',
  160: 'KILOGRAM_PER_SECOND',
  161: 'GRAM_PER_SECOND',
  162: 'HECTO_PASCAL',
  163: 'MILLITORR_PER_HOUR',
};
