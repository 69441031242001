import {
  EChartTimeFormat,
  TChartTimeFormatTranslation,
} from './select-chart-time-format.ts';

const chartTimeFormats: TChartTimeFormatTranslation = {
  [EChartTimeFormat.COMPACT]: {
    displayName: 'Kompakt',
    details: 'Zeigt nur die Uhrzeit für Zeiträume unter 24 Stunden an',
  },
  [EChartTimeFormat.DETAILED]: {
    displayName: 'Detailliert',
    details: 'Zeigt immer das vollständige Datum an',
  },
};

export default {
  placeholder: 'Diagrammzeitformat auswählen',
  chartTimeFormats,
};
